import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from 'sunwise-ui';

import { SOURCE_FIELD_KEYS } from '../../constants';

import ControlledInfoChip from './ControlledInfoChip';

const SourceInfoFields = ({
    control,
    disabled,
    fromFinancing,
    validFields,
}) => {
    const { t } = useTranslation();

    return (
        <Box alignItems="center" display="flex" flexWrap="wrap" gap={2} mb={2}>
            <Typography variant="body2">{t('Information')}:</Typography>

            {Object.values(SOURCE_FIELD_KEYS).map(
                (key) =>
                    (!validFields || validFields.includes(key)) && (
                        <ControlledInfoChip
                            control={control}
                            disabled={disabled}
                            fromFinancing={fromFinancing}
                            key={key}
                            name={key}
                        />
                    ),
            )}
        </Box>
    );
};

SourceInfoFields.propTypes = {
    control: PropTypes.object,
    disabled: PropTypes.bool,
    fromFinancing: PropTypes.bool,
    validFields: PropTypes.array,
};

export default SourceInfoFields;
