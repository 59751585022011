import API from '../..';
const ENTITY = 'projects';

export const create = (values) =>
    API.post(`/api/v2/${ENTITY}/comments/`, values);
export const getItems = (projectId) =>
    API.get(`/api/v2/${ENTITY}/${projectId}/comments/`);
export const update = (commentId, values) =>
    API.put(`/api/v2/${ENTITY}/comments/${commentId}/`, values);
export const remove = (commentId) =>
    API.delete(`/api/v2/${ENTITY}/comments/${commentId}/`);
