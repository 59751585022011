import React, { useEffect, useMemo } from 'react';

import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Mention, MentionsInput } from 'react-mentions';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { Box, Button, Card, FormControl, FormLabel, Grid } from 'sunwise-ui';

import {
    ReactHookFormCheck,
    ReactHookFormMultiSelect,
    ReactHookFormSelect,
    RHFDateTimePicker,
} from 'common/components/form/bootstrap';
import ShowErrors from 'common/components/ShowErrors';
import { formatDate, zonedDate } from 'common/utils/dates';
import { getTimezoneUserSettings } from 'common/utils/helpers/multiregion';
import yupResolver from 'common/utils/yupResolver';

import * as profileSelectors from '../../profile/selectors';
import * as projectsActions from '../../project/actions';
import * as projectsSelectors from '../../project/selectors';
import { getUsersOptions, getTrackingTypesOptions } from '../helpers';
import validate from '../validate';

import mentionsInputStyle from './mentionsInputStyle';
import mentionStyle from './mentionStyle';

const Form = ({
    agentId,
    errors,
    fetchProjects,
    fetchTrackingsPurposes,
    handleClickCancel,
    initialValues,
    isSaving,
    match,
    onSubmit,
    projectId,
    projects,
    trackingsPurposesToSelect,
    users,
}) => {
    const { t } = useTranslation();

    const { control, handleSubmit, reset, setValue, watch, getValues } =
        useForm({
            defaultValues: { ...initialValues, assigned_to: [agentId] },
            resolver: yupResolver(validate),
        });

    useEffect(() => {
        const currentDate = zonedDate(new Date(), getTimezoneUserSettings());
        const dateTimeToday = formatDate(currentDate, 'yyyy-MM-dd H:mm');
        reset({
            ...getValues(),
            project: projectId,
            notification_at: dateTimeToday,
        });
    }, [initialValues, projectId]);

    const remember = watch('remember');

    useEffect(() => {
        fetchTrackingsPurposes();
        fetchProjects({ contactId: match.params.uid });
    }, []);

    const optionsForSelect = useMemo(
        () =>
            users?.map((user) => ({
                label: `${user.first_name} ${user.last_name}`,
                value: user.id,
            })),
        [users],
    );

    const projectsToSelect = useMemo(() => {
        const mapped = projects
            ?.map(({ id, name }) => ({
                label: name,
                value: id,
            }))
            .sort((a, b) => (a.name > b.name ? 1 : -1));
        mapped.unshift({ label: t('None'), value: null });
        return mapped;
    }, [projects]);

    return (
        <Card
            sx={{
                background: (theme) =>
                    theme.palette.mode === 'dark'
                        ? 'rgb(40, 43, 48)'
                        : 'rgba(238, 244, 250, 0.35)',
                my: 2,
            }}
        >
            <Card.Body>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <ReactHookFormSelect
                        control={control}
                        label={t('Tracking type')}
                        name="tracking_type"
                        options={getTrackingTypesOptions()}
                    />

                    <ReactHookFormSelect
                        control={control}
                        label={t('Objective')}
                        name="tracking_purpose"
                        options={trackingsPurposesToSelect}
                    />

                    {!projectId && (
                        <ReactHookFormSelect
                            control={control}
                            label={t('Project')}
                            name="project"
                            options={projectsToSelect}
                        />
                    )}

                    <FormControl fullWidth sx={{ height: '100px' }}>
                        <FormLabel
                            sx={{ fontSize: '13px', fontWeight: 'bold' }}
                        >
                            {t('Note')}
                        </FormLabel>
                        <MentionsInput
                            onChange={(e) =>
                                setValue('description', e.target.value)
                            }
                            placeholder={t('Add note')}
                            style={{
                                ...mentionsInputStyle,
                                control: {
                                    backgroundColor: '#fff',
                                    borderRadius: '8px',
                                    padding: '8.5px 14px',
                                    height: '92px',
                                },
                            }}
                            value={watch('description')}
                        >
                            <Mention
                                data={getUsersOptions(users)}
                                style={mentionStyle}
                                trigger="@"
                            />
                        </MentionsInput>
                    </FormControl>

                    <ReactHookFormCheck
                        control={control}
                        label={t('Remember task')}
                        name="remember"
                    />

                    {remember && (
                        <Box>
                            {users.length > 1 && (
                                <ReactHookFormMultiSelect
                                    allTextValue={t('All text', { count: 2 })}
                                    control={control}
                                    disableFuture
                                    label={t('Assigned to')}
                                    name="assigned_to"
                                    options={optionsForSelect || []}
                                />
                            )}

                            <RHFDateTimePicker
                                control={control}
                                disabled={!remember}
                                fullWidth
                                disablePast
                                label={t('Date')}
                                name="notification_at"
                            />
                        </Box>
                    )}

                    <Grid container>
                        <Grid size={18}>
                            <ShowErrors errors={errors} />
                        </Grid>
                    </Grid>

                    <Grid container sx={{ mt: 2 }}>
                        <Grid
                            display="flex"
                            flexDirection={{ xs: 'column', md: 'row' }}
                            justifyContent={{ md: 'right', xs: 'center' }}
                            size={{ xs: 'grow' }}
                        >
                            <Button
                                color="secondary"
                                onClick={handleClickCancel}
                                sx={{
                                    mr: { xs: 0, md: 2 },
                                    order: { xs: 2, md: 1 },
                                    width: { xs: '100%', md: 'auto' },
                                }}
                                variant="text"
                            >
                                {t('Cancel')}
                            </Button>
                            <Button
                                color="secondary"
                                disabled={isSaving}
                                sx={{
                                    mb: { xs: 2, md: 0 },
                                    order: { xs: 1, md: 2 },
                                    width: { xs: '100%', md: 'auto' },
                                }}
                                type="submit"
                                variant="outlined"
                            >
                                {t('Save')}
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Card.Body>
        </Card>
    );
};

const mapStateToProps = createStructuredSelector({
    agentId: profileSelectors.getUserId,
    projects: projectsSelectors.getDataProjects,
});

const mapDispatchToProps = (dispatch) => ({
    fetchProjects: (filterData, callback) =>
        dispatch(projectsActions.fetchProjects(filterData, callback)),
});

Form.propTypes = {
    agentId: PropTypes.string,
    errors: PropTypes.array,
    fetchProjects: PropTypes.func,
    fetchTrackingsPurposes: PropTypes.func,
    handleClickCancel: PropTypes.func,
    initialValues: PropTypes.object,
    isSaving: PropTypes.bool,
    match: PropTypes.object,
    onSubmit: PropTypes.func,
    projectId: PropTypes.string,
    projects: PropTypes.array,
    trackingsPurposesToSelect: PropTypes.array,
    users: PropTypes.array,
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withRouter,
)(Form);
