import isNull from 'lodash/isNull';

import { createItem, updateItem } from 'common/api/v1/proposalsPlans';
import showToast from 'common/utils/showToast';

import {
    SAVE_ITEM,
    SAVE_ITEM_FAILURE,
    SAVE_ITEM_SUCCESS,
} from '../actionTypes';
import { planConfigurationActions } from '../reducer';

import closeModalProduct from './closeModalProduct';
import filterItems from './filterItems';

const handleCheckboxField = (values) => {
    const object_keys = Object.keys(values);
    let temp = [];

    object_keys.forEach((item) => {
        if (values[item] === true) temp.push(parseInt(item));
    });

    return temp;
};

export default (filterData, values) => (dispatch) => {
    const installation_type = JSON.stringify(
        handleCheckboxField(values.installation_type),
    );

    let newValues = {
        battery: values.battery,
        considerations: values.considerations,
        cost_ranges_kwh: JSON.stringify(values.cost_ranges_kwh),
        cost_ranges_watt: JSON.stringify(values.cost_ranges_watt),
        costing_type: values.costing_type,
        currency: values.currency,
        custom_offer_template: values.custom_offer_template,
        desired_power_factor_enabled: values.apply_desired_power_factor,
        desired_power_factor: values.desired_power_factor,
        external_custom_template_id: values.external_custom_template_id,
        guaranteed_gen_active: values.guaranteed_gen_active || false,
        guaranteed_gen: values.guaranteed_gen,
        installation_type,
        inverter_brand: values.inverter_brand,
        is_active: true,
        isr_simulation_type: values.isr_simulation_type,
        isr_simulation: values.isr_simulation,
        name: values.name,
        opex_active: values.opex_active,
        opex_cost_ranges_kw: JSON.stringify(values.opex_cost_ranges_kw),
        opex_cost_ranges_kwh: JSON.stringify(values.opex_cost_ranges_kwh),
        opex_costing_type: values.opex_costing_type,
        opex_percentage: values.opex_percentage_increase,
        opex_periodicity: values.opex_periodicity,
        opex: values.opex,
        panel: values.panel,
        ppa_active: values.ppa_active,
        ppa_price: values.ppa_price,
        pre_2018_enabled: values.deprecated,
        saving_percentage: values.saving_percentage,
        simulation_years: values.simulation_years,
        start_in_months: parseInt(values.generation_delay),
        tax_incentive_enabled: values.isr,
        tax_incentive_percentage: values.isr_percentage,
        validity: values.validity,
    };

    if (!isNull(values.branch_office)) {
        newValues.branch_office = values.branch_office;
    }

    let workforce_and_electric_materials = [];
    let accessories = [];
    let structures = [];
    let additionals = [];

    if (values.workforce_and_electric_materials.length) {
        for (
            let i = 0;
            i < values.workforce_and_electric_materials.length;
            i++
        ) {
            if (values.workforce_and_electric_materials[i].item) {
                workforce_and_electric_materials.push(
                    values.workforce_and_electric_materials[i].item,
                );
            }
        }

        if (workforce_and_electric_materials.length) {
            newValues.workforce_and_electric_materials = JSON.stringify(
                workforce_and_electric_materials,
            );
        }
    }

    if (values.accessories.length) {
        for (let i = 0; i < values.accessories.length; i++) {
            if (values.accessories[i].item) {
                accessories.push(values.accessories[i].item);
            }
        }

        if (accessories.length) {
            newValues.accessories = JSON.stringify(accessories);
        }
    }

    if (values.structures.length) {
        for (let i = 0; i < values.structures.length; i++) {
            if (values.structures[i].item) {
                structures.push(values.structures[i].item);
            }
        }

        if (structures.length) {
            newValues.structures = JSON.stringify(structures);
        }
    }

    if (values.additionals.length) {
        for (let i = 0; i < values.additionals.length; i++) {
            if (values.additionals[i].item) {
                additionals.push(values.additionals[i].item);
            }
        }

        if (additionals.length) {
            newValues.additionals = JSON.stringify(additionals);
        }
    }

    dispatch(planConfigurationActions[SAVE_ITEM]());

    const save = values.id
        ? () => updateItem(newValues, values.id)
        : () => createItem(newValues);

    save()
        .then(() => {
            dispatch(planConfigurationActions[SAVE_ITEM_SUCCESS]());
            dispatch(filterItems(filterData));
            dispatch(closeModalProduct(false));
            showToast();
        })
        .catch((error) =>
            dispatch(
                planConfigurationActions[SAVE_ITEM_FAILURE](
                    error?.response?.data?.errors,
                ),
            ),
        );
};
