import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { UiDialog as Dialog } from 'sunwise-ui';

import * as supplierIntegrationsActions from '../../supplierIntegrations/actions';
import * as actions from '../actions';
import * as selectors from '../selectors';

import Form from './Form';

const ModalForm = ({
    canModifyAdvancedFeatures,
    canViewAdvancedFeatures,
    fetchSupplierIntegrations,
    fetchTemplates,
    fromFinancing,
    isModalFormOpen,
    onSuccess = null,
    selectedProjectId,
    selectedCommercialOfferId = null,
    setModalFormOpenStatus,
}) => {
    const { t } = useTranslation();

    const handleOnClose = () => setModalFormOpenStatus(false);

    const onEnter = () => {
        fetchSupplierIntegrations();
        fetchTemplates();
    };

    return (
        <Dialog
            onClose={handleOnClose}
            onEnter={onEnter}
            open={isModalFormOpen}
            size="md"
            title={t('Project settings')}
        >
            <Form
                canModifyAdvancedFeatures={canModifyAdvancedFeatures}
                canViewAdvancedFeatures={canViewAdvancedFeatures}
                fromFinancing={fromFinancing}
                onClose={handleOnClose}
                onSuccess={onSuccess}
                selectedProjectId={selectedProjectId}
                selectedCommercialOfferId={selectedCommercialOfferId}
            />
        </Dialog>
    );
};

ModalForm.propTypes = {
    canModifyAdvancedFeatures: PropTypes.bool,
    canViewAdvancedFeatures: PropTypes.bool,
    fetchSupplierIntegrations: PropTypes.func,
    fetchTemplates: PropTypes.func,
    fromFinancing: PropTypes.bool,
    isModalFormOpen: PropTypes.bool,
    onSuccess: PropTypes.func,
    selectedProjectId: PropTypes.string,
    selectedCommercialOfferId: PropTypes.string,
    setModalFormOpenStatus: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
    isModalFormOpen: selectors.getIsModalFormOpen,
});

const mapDispatchToProps = (dispatch) => ({
    fetchSupplierIntegrations: () =>
        dispatch(supplierIntegrationsActions.fetchIntegrations()),
    fetchTemplates: () => dispatch(actions.fetchTemplates()),
    setModalFormOpenStatus: (value) =>
        dispatch(actions.setModalFormOpenStatus(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalForm);
