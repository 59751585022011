import React, { useEffect, useMemo, useRef, useState } from 'react';

import Battery0BarIcon from '@mui/icons-material/Battery0Bar';
import BatteryChargingFullIcon from '@mui/icons-material/BatteryChargingFull';
import BatteryFullIcon from '@mui/icons-material/BatteryFull';
import BatteryUnknownIcon from '@mui/icons-material/BatteryUnknown';
import {
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
} from '@sunwisesoftware/sunwise-ui';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import ChartJs from 'common/components/ChartJs';
import { ChartControls } from 'common/components/charts';
import { CHART_OPTIONS } from 'common/components/charts/ChartControls';
import { HOURS_IN_YEAR } from 'common/constants';
import { differenceInWeeksDate } from 'common/utils/dates';
import { numberFormat } from 'common/utils/helpers';
import {
    generateColorTone,
    getDataProfileFormattedByPeriods,
    getEnergyChartLabels,
    getSeriesByOption,
    getTooltipDateRange,
    hexToRgba,
    populateDailySeries,
    populatePeriodicallySeries,
    populateWeeklyByHourSeries,
    populateWeeklySeries,
    populateYearlyProposalEnergySeries,
} from 'common/utils/helpersChart';

import {
    BACKUP_VISIBILITY,
    COLORS_CHART,
    ORIGINAL_SERIE_NAME,
} from '../constants';
import { distributeConsumptionProfile } from '../helpers';

const hourlyArray = [...Array(HOURS_IN_YEAR).keys()];

const getChartSeries = ({
    consumptionProfile,
    dateFormat,
    datesSummary,
    selectedDays,
    selectedOption,
    selectedPeriod,
    selectedWeeks,
    selectedYear,
}) => {
    const seriesData = [
        {
            data: consumptionProfile?.original,
            name: ORIGINAL_SERIE_NAME,
        },
    ];

    for (const deviceName in consumptionProfile?.devices)
        seriesData.push({
            data: consumptionProfile?.devices[deviceName],
            name: deviceName,
        });

    const { profileFormattedByPeriods } = getDataProfileFormattedByPeriods({
        dateFormat: dateFormat,
        profile: hourlyArray,
        summary: datesSummary,
    });

    const { series } = getSeriesByOption({
        data: {
            realIndex: profileFormattedByPeriods,
            selectedDays,
            selectedOption,
            selectedPeriod,
            selectedWeeks,
            selectedYear,
            series: seriesData,
        },
        options: {
            [CHART_OPTIONS.DAILY]: populateDailySeries,
            [CHART_OPTIONS.MONTHLY]: populatePeriodicallySeries,
            [CHART_OPTIONS.WEEKLY]: populateWeeklySeries,
            [CHART_OPTIONS.WEEKLY_BY_HOUR]: populateWeeklyByHourSeries,
            [CHART_OPTIONS.YEARLY]: populateYearlyProposalEnergySeries,
        },
    });

    return series;
};

const getWeeksToSelect = ({ finalDate, initialDate }) => {
    const diff = differenceInWeeksDate(finalDate, initialDate);

    return (Math.max(diff, 0) || 0) + 1;
};

const EnergyChart = ({ countryCurrencyLocale, formValues }) => {
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const [consumptionProfileData, setConsumptionProfileData] = useState(null);
    const [chartConfig, setChartConfig] = useState({
        data: { datasets: [] },
        options: {},
    });
    const [backupVisibility, setBackupVisibility] = useState(null);
    const [chartType, setChartType] = useState('area');
    const [defaultVisibility, setDefaultVisibility] = useState();
    const [selectedDays, setSelectedDays] = useState([1]);
    const [selectedOption, setSelectedOption] = useState(0);
    const [selectedPeriod, setSelectedPeriod] = useState(0);
    const [selectedWeeks, setSelectedWeeks] = useState([0]);

    const chartRef = useRef(null);

    const selectedPeriodDates =
        formValues?.dates_summary?.[selectedPeriod] || {};

    const weeksToSelect = useMemo(() => {
        return getWeeksToSelect({
            finalDate: selectedPeriodDates?.parsed_final_date,
            initialDate: selectedPeriodDates?.parsed_initial_date,
        });
    }, [selectedPeriodDates?.final_date, selectedPeriodDates?.initial_date]);

    useEffect(() => {
        const newConsumptionProfileData = distributeConsumptionProfile({
            consumptionProfile: formValues.consumption_profile_array,
            dateFormat: formValues.date_format,
            devices: formValues.devices,
            finalDate:
                formValues.dates_summary?.[formValues.dates_summary.length - 1]
                    ?.final_date,
            initialConsumption: formValues.initial_consumption,
            initialDate: formValues.dates_summary?.[0]?.initial_date,
            returnValuesInKW: true,
            summarize: false,
        });

        const newSelectedWeeks = selectedWeeks.filter(
            (week) => week < weeksToSelect,
        );

        handleChartChange({
            consumptionProfileData: newConsumptionProfileData,
            selectedDays,
            selectedOption,
            selectedPeriod,
            selectedWeeks: newSelectedWeeks,
        });

        setConsumptionProfileData(newConsumptionProfileData);
        setSelectedWeeks(newSelectedWeeks);
    }, [formValues]);

    const handleOnLegendClick = ({ label, series = [] }) => {
        setDefaultVisibility((prev) => {
            const currentSeries =
                prev ?? (series ?? []).map((serie) => serie.label);
            const isCurrentlyVisible = currentSeries.includes(label);
            return isCurrentlyVisible
                ? currentSeries.filter((index) => index !== label)
                : [...currentSeries, label];
        });
    };

    const handleChartChange = ({
        consumptionProfileData,
        selectedDays,
        selectedOption,
        selectedPeriod,
        selectedWeeks,
    }) => {
        const series = getChartSeries({
            consumptionProfile:
                consumptionProfileData?.consumptionProfileDevices,
            dateFormat: formValues?.date_format,
            datesSummary: formValues?.dates_summary,
            selectedDays,
            selectedOption,
            selectedPeriod,
            selectedWeeks,
            selectedYear: 0,
        });

        const seriesMultiplier = consumptionProfileData?.totalConsumption / 100;

        const { [ORIGINAL_SERIE_NAME]: __original__, ...restSeries } = series;

        const datasets = [
            {
                backgroundColor: hexToRgba(COLORS_CHART.ORIGINAL, 0.8),
                borderColor: COLORS_CHART.ORIGINAL,
                data: __original__?.map((v) => v * seriesMultiplier) || [],
                label: t('Installed'),
                stack: 'consumption',
            },
        ];

        let deviceIndex = 0;
        const colorDelta = 25 / COLORS_CHART.DEVICES.length;

        for (const deviceName in restSeries) {
            const baseColor =
                COLORS_CHART.DEVICES[deviceIndex % COLORS_CHART.DEVICES.length];

            const color = generateColorTone(
                baseColor,
                parseInt(colorDelta * deviceIndex),
            );

            datasets.push({
                backgroundColor: hexToRgba(color, 0.8),
                borderColor: color,
                data: restSeries[deviceName]?.map((v) => v * seriesMultiplier),
                label: deviceName,
                stack: 'consumption',
            });

            deviceIndex++;
        }
        const categories = getEnergyChartLabels({
            selectedOption,
            selectedPeriod,
            selectedWeeks,
            series: datasets,
            seriesEnergy: formValues?.dates_summary,
        });

        const data = { datasets, labels: categories || [] };

        const isDailyChart = selectedOption === CHART_OPTIONS.DAILY;

        const options = {
            interaction: { intersect: false, mode: 'nearest' },
            plugins: {
                legend: {
                    onClick: function (_, legendItem) {
                        const chart = this.chart;
                        const label = legendItem.text;
                        handleOnLegendClick({
                            label,
                            series: chart.data.datasets,
                        });
                    },
                },
                tooltip: {
                    callbacks: {
                        title: (context) => {
                            const ctx = context[0];
                            const values = ctx.parsed._stacks.y._visualValues;
                            const _metasets = ctx.chart._metasets;
                            const valuesLength = Object.keys(values).length - 1;
                            let total = 0;

                            for (let i = 0; i <= valuesLength; i++) {
                                if (_metasets[i].hidden) continue;
                                total += values[i];
                            }

                            const formattedTotal = numberFormat(total, {
                                decimals: 2,
                                locale: countryCurrencyLocale,
                                style: 'decimal',
                                unit: isDailyChart ? 'kW' : 'kWh',
                            });

                            if (selectedOption !== CHART_OPTIONS.YEARLY)
                                return `${ctx.label} - ${formattedTotal}`;

                            return `${getTooltipDateRange({
                                index: ctx.dataIndex,
                                seriesEnergy: formValues?.dates_summary,
                            })} - ${formattedTotal}`;
                        },
                        label: (context) => {
                            return (
                                context.dataset.label +
                                ': ' +
                                numberFormat(context.parsed.y, {
                                    decimals: 2,
                                    locale: countryCurrencyLocale,
                                    style: 'decimal',
                                    unit: isDailyChart ? 'kW' : 'kWh',
                                })
                            );
                        },
                    },
                },
            },
            scales: {
                x: { grid: { drawOnChartArea: false } },
                y: {
                    ticks: {
                        callback: (val) =>
                            numberFormat(val, {
                                decimals: 0,
                                locale: countryCurrencyLocale,
                                style: 'decimal',
                            }),
                    },
                    title: { display: true, text: isDailyChart ? 'kW' : 'kWh' },
                },
            },
        };

        setChartConfig({ data, options });

        handleChangeDefaultVisibility(backupVisibility, data?.datasets);
    };

    const handleOnChangeSelectedOption = (e) => {
        const value = parseInt(e.target.value);
        setSelectedOption(value);

        handleChartChange({
            consumptionProfileData,
            selectedDays,
            selectedOption: value,
            selectedPeriod,
            selectedWeeks,
        });
    };

    const handleOnChangeSelectedPeriod = (e) => {
        const value = parseInt(e.target.value);
        setSelectedPeriod(value);

        handleChartChange({
            consumptionProfileData,
            selectedDays,
            selectedOption,
            selectedPeriod: value,
            selectedWeeks,
        });
    };

    const handleOnChangeSelectedDays = (e) => {
        let value = e.target.value;
        if (value[value.length - 1] === 'all')
            value = selectedDays.length === 7 ? [] : [0, 1, 2, 3, 4, 5, 6];
        setSelectedDays(value);

        handleChartChange({
            consumptionProfileData,
            selectedDays: value,
            selectedOption,
            selectedPeriod,
            selectedWeeks,
        });
    };

    const handleOnChangeSelectedWeeks = (e) => {
        let value = e.target.value;
        if (value[value.length - 1] === 'all')
            value =
                selectedWeeks.length === weeksToSelect
                    ? []
                    : [...Array(weeksToSelect).keys()];
        setSelectedWeeks(value);

        handleChartChange({
            consumptionProfileData,
            selectedDays,
            selectedOption,
            selectedPeriod,
            selectedWeeks: value,
        });
    };

    const series = chartConfig?.data?.datasets.map((dataset) => ({
        name: dataset.label,
    }));

    const handleClick = (event) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);

    const handleChangeDefaultVisibility = (backupType, datasets) => {
        if (!backupType || !BACKUP_VISIBILITY_CONFIG[backupType]) return;

        let items = [];

        if (backupType === BACKUP_VISIBILITY.ALL) {
            items = datasets?.map((dataset) => dataset.label) ?? [];
        } else {
            const isBackup = backupType === BACKUP_VISIBILITY.ONLY_BACKUP;

            items =
                formValues?.devices
                    ?.filter(
                        (device) =>
                            !device.installed && !!device.backup === isBackup,
                    )
                    .map((device) => device.device_name) ?? [];

            if (!isBackup) items.push(t('Installed'));
        }

        setDefaultVisibility(items);
    };

    const BACKUP_VISIBILITY_CONFIG = {
        [BACKUP_VISIBILITY.ALL]: {
            icon: <BatteryFullIcon />,
            label: t('Show all'),
            value: BACKUP_VISIBILITY.ALL,
        },
        [BACKUP_VISIBILITY.ONLY_BACKUP]: {
            icon: <BatteryChargingFullIcon />,
            label: t('Only backup'),
            value: BACKUP_VISIBILITY.ONLY_BACKUP,
        },
        [BACKUP_VISIBILITY.NO_BACKUP]: {
            icon: <Battery0BarIcon />,
            label: t('Hide backup'),
            value: BACKUP_VISIBILITY.NO_BACKUP,
        },
    };

    const backupVisibilityOptions = Object.values(BACKUP_VISIBILITY_CONFIG);

    return (
        <>
            {consumptionProfileData && (
                <ChartControls
                    chartRef={chartRef}
                    chartType={chartType}
                    chartTypeOptions={['area', 'bar']}
                    consumptionHistory={formValues?.dates_summary}
                    customIconButtons={[
                        {
                            icon: backupVisibility ? (
                                BACKUP_VISIBILITY_CONFIG[backupVisibility].icon
                            ) : (
                                <BatteryUnknownIcon />
                            ),
                            onClick: handleClick,
                            label: t('Show backup'),
                        },
                    ]}
                    datasets={series}
                    defaultVisibility={defaultVisibility}
                    handleOnChangePeriod={handleOnChangeSelectedOption}
                    handleOnChangeSelectedDays={handleOnChangeSelectedDays}
                    handleOnChangeSelectedPeriod={handleOnChangeSelectedPeriod}
                    handleOnChangeSelectedWeeks={handleOnChangeSelectedWeeks}
                    onChangeVisibility={setDefaultVisibility}
                    selectedDays={selectedDays}
                    selectedOption={selectedOption}
                    selectedPeriod={selectedPeriod}
                    selectedWeeks={selectedWeeks}
                    series={series}
                    setChartType={setChartType}
                    showVisibilityControls
                    weeksToSelect={weeksToSelect}
                />
            )}

            <Menu
                anchorEl={anchorEl}
                anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                onClose={handleClose}
                open={open}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            >
                {backupVisibilityOptions.map(
                    ({ icon, label, value }, index) => (
                        <MenuItem
                            key={index}
                            onClick={() => {
                                handleClose();
                                setBackupVisibility(value);
                                handleChangeDefaultVisibility(
                                    value,
                                    chartConfig?.data?.datasets,
                                );
                            }}
                        >
                            <ListItemIcon
                                sx={{ minWidth: '24px !important', mr: 1 }}
                            >
                                {icon}
                            </ListItemIcon>
                            <ListItemText primary={label} />
                        </MenuItem>
                    ),
                )}
            </Menu>

            <ChartJs
                chartRef={chartRef}
                data={chartConfig?.data}
                options={chartConfig?.options}
                type={chartType === 'area' ? 'line' : chartType}
            />
        </>
    );
};

EnergyChart.propTypes = {
    countryCurrencyLocale: PropTypes.string,
    formValues: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({});

export default connect(mapStateToProps, null)(EnergyChart);
