import React from 'react';

import AddIcon from '@mui/icons-material/Add';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Button, Card, Typography } from 'sunwise-ui';

import PlaceholderCardDocuments from 'common/components/placeholder/PlaceholderCardDocuments';

import DocumentItem from './DocumentItem';

const DocumentList = ({
    canDelete,
    canModify,
    documents,
    handleClickAdd,
    handleClickDelete,
    handleClickUpdate,
    isFetchingDocuments,
}) => {
    const { t } = useTranslation();
    return (
        <PlaceholderCardDocuments ready={isFetchingDocuments}>
            <Card>
                <Card.Header>
                    <Typography variant="body1" fontWeight="bold">
                        {t('Project document', { count: 2 })}
                    </Typography>
                </Card.Header>

                {documents?.length > 0 && (
                    <Card.Body>
                        {documents.map((document) => {
                            return (
                                <DocumentItem
                                    canDelete={canDelete}
                                    canModify={canModify}
                                    createdAt={document.createdAt}
                                    documentType={
                                        document.documentType &&
                                        document.documentType.name
                                    }
                                    file={document.file}
                                    key={`project-document-item-${document.id}`}
                                    handleClickUpdate={() =>
                                        handleClickUpdate(document)
                                    }
                                    handleClickDelete={() =>
                                        handleClickDelete(document)
                                    }
                                    valueUser={document.valueUser}
                                />
                            );
                        })}
                    </Card.Body>
                )}

                <Box sx={{ width: '100%', p: 2 }}>
                    {canModify && (
                        <Button
                            color="secondary"
                            endIcon={<AddIcon />}
                            fullWidth
                            onClick={handleClickAdd}
                            variant="outlined"
                        >
                            {t('Add document', { count: 2 })}
                        </Button>
                    )}
                </Box>
            </Card>
        </PlaceholderCardDocuments>
    );
};

DocumentList.propTypes = {
    canDelete: PropTypes.bool,
    canModify: PropTypes.bool,
    handleClickAdd: PropTypes.func,
    handleClickUpdate: PropTypes.func,
    handleClickDelete: PropTypes.func,
    isFetchingDocuments: PropTypes.bool,
    documents: PropTypes.array,
};

export default DocumentList;
