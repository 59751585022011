import { create, update } from 'common/api/v2/projectsComments';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import {
    SAVE_NOTE,
    SAVE_NOTE_SUCCESS,
    SAVE_NOTE_FAILURE,
} from '../actionTypes';
import { projectActions } from '../reducer';

import fetchNotes from './fetchNotes';

export default (values, callback) => (dispatch) => {
    const { id, project } = values;

    const save = id ? () => update(id, values) : () => create(values);

    dispatch(projectActions[SAVE_NOTE]());

    save()
        .then((response) => {
            dispatch(projectActions[SAVE_NOTE_SUCCESS](response.data));

            if (callback) callback();

            dispatch(fetchNotes(project));
            showToast();
        })
        .catch((error) => {
            dispatch(
                projectActions[SAVE_NOTE_FAILURE](
                    error?.response?.data?.errors,
                ),
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
