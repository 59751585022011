import React, { useState } from 'react';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CommentIcon from '@mui/icons-material/Comment';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import FolderIcon from '@mui/icons-material/Folder';
import GroupIcon from '@mui/icons-material/Group';
import PersonIcon from '@mui/icons-material/Person';
import { useTheme } from '@mui/material/styles';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Mention, MentionsInput } from 'react-mentions';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
    Box,
    Button,
    Card,
    Grid,
    IconButton,
    Tooltip,
    Typography,
    UiDialog as Dialog,
} from 'sunwise-ui';

import DropdownCardMenu from 'common/components/cards/DropdownCardMenu';
import {
    formatDistanceDate,
    parseDate,
    formatDate,
    zonedDate,
} from 'common/utils/dates';
import { trackingTypeToLabel, truncateString } from 'common/utils/helpers';
import { getTimezoneUserSettings } from 'common/utils/helpers/multiregion';

import * as profileSelectors from '../../profile/selectors';
import { MAXIMUM_CHARACTERS_FOR_TRACKING } from '../constants';
import { getUsersOptions } from '../helpers';

import CommentForm from './CommentForm';
import CommentsList from './CommentsList';
import mentionsMessageStyle from './mentionsMessageStyle';
import mentionStyle from './mentionStyle';
import ReasignTrackingForm from './ReasignTrackingForm';
import TrackingTypeIcon from './TrackingTypeIcon';

const TrackingItem = ({
    canDelete,
    canModify,
    data,
    handleClickChangeDate,
    handleClickDelete,
    handleClickMarkAsCompleted,
    users,
}) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const isDarkMode = theme.palette.mode === 'dark';
    const [maxCharacters, setMaxCharacters] = useState(
        MAXIMUM_CHARACTERS_FOR_TRACKING,
    );
    const [isOpenModalTracking, setIsOpenModalTracking] = useState(false);
    const [isOpenCommentArea, setIsOpenCommentArea] = useState(false);

    const agent = data?.agent || {};
    const assigned_to = data.assigned_to || [];
    const completed = data.completed;
    const currentDate = zonedDate(new Date(), getTimezoneUserSettings());
    const description = data.description;
    const goal = data.tracking_purpose?.name || '---';
    const id = data.id;
    const project = data?.project || {};
    const remember = data.remember;
    const trackingType = data.tracking_type;
    const totalComments = data.total_comments || 0;
    let createdAt = '';
    let trackingDate = '';
    let hasExpired = false;

    if (data.notification_at) {
        const targetDate = parseDate(
            data?.notification_at,
            'dd/MM/yyyy HH:mm:ss',
        );
        createdAt = formatDistanceDate(
            targetDate,
            zonedDate(new Date(), getTimezoneUserSettings()),
            { addSuffix: true },
        );
        trackingDate = formatDate(targetDate, 'dd MMMM yyyy h:mm aa');
        hasExpired = currentDate > targetDate;
    }

    const defaultUser = `${get(agent, 'first_name', '---')} ${get(
        agent,
        'last_name',
        '---',
    )}`;

    const projectName = get(project, 'name', '---');

    const filterAssignedTo =
        assigned_to?.filter((user) => user.id !== agent.id) || [];

    const ohterUsers = filterAssignedTo.map(
        (user) =>
            `${get(user, 'first_name', '---')} ${get(user, 'last_name', '---')}`,
    );

    const agents = [defaultUser, ...ohterUsers];

    const tooggleCommentArea = () => setIsOpenCommentArea((prev) => !prev);

    let tooltipTitle = t('Mark as completed');
    let colorIcon = '';
    let onClickIcon = handleClickMarkAsCompleted;

    if (completed) {
        tooltipTitle = t('Remember again');
        colorIcon = 'success';
        onClickIcon = () => setIsOpenModalTracking(true);
    }

    return (
        <Card sx={{ my: 2 }}>
            <Card.Header sx={{ backgroundColor: 'rgb(238 244 250 / 35%)' }}>
                <Box alignItems="center" display="flex" gap={1}>
                    <Grid
                        container
                        sx={{
                            columnGap: 1,
                            rowGap: {
                                sm: 0,
                                xs: 1,
                            },
                            width: '100%',
                        }}
                    >
                        <Grid
                            size={{ sm: 'auto', xs: 18 }}
                            sx={{
                                alignItems: 'center',
                                display: 'flex',
                                gap: 0.5,
                            }}
                        >
                            <TrackingTypeIcon
                                sx={{ fontSize: 18 }}
                                type={trackingType}
                            />

                            <Typography fontWeight="bold" variant="caption">
                                {trackingTypeToLabel(trackingType)}
                            </Typography>
                        </Grid>

                        <Grid
                            size={{ sm: 'auto', xs: 18 }}
                            sx={{
                                alignItems: 'center',
                                display: 'flex',
                            }}
                        >
                            <Typography variant="caption">
                                {t('Objective')}:{' '}
                                <Typography fontWeight="bold" variant="caption">
                                    {goal}
                                </Typography>
                            </Typography>
                        </Grid>

                        <Grid
                            size={{ sm: 'auto', xs: 18 }}
                            sx={{
                                alignItems: 'center',
                                display: 'flex',
                                gap: 0.5,
                            }}
                        >
                            {agents.length > 1 ? (
                                <GroupIcon sx={{ fontSize: 18 }} />
                            ) : (
                                <PersonIcon sx={{ fontSize: 18 }} />
                            )}{' '}
                            <Typography variant="caption">
                                {agents.join(', ')}
                            </Typography>
                        </Grid>

                        <Grid
                            size={{ sm: 'auto', xs: 18 }}
                            sx={{
                                alignItems: 'center',
                                display: 'flex',
                                gap: 0.5,
                            }}
                        >
                            <FolderIcon sx={{ fontSize: 18 }} />
                            <Typography variant="caption">
                                {projectName}
                            </Typography>
                        </Grid>

                        {remember && (
                            <Grid
                                size={{ lg: 'auto', xs: 18 }}
                                sx={{ ml: { lg: 'auto', xs: 0 } }}
                            >
                                <Typography
                                    sx={{ fontSize: '0.7rem' }}
                                    variant="caption"
                                >
                                    {trackingDate} -{' '}
                                    {hasExpired ? t('Expired') : t('Expires')}{' '}
                                    {createdAt}
                                </Typography>
                            </Grid>
                        )}
                    </Grid>

                    <Box sx={{ ml: 'auto' }}>
                        <DropdownCardMenu
                            items={[
                                {
                                    handleClick: handleClickDelete,
                                    icon: <DeleteIcon fontSize="small" />,
                                    label: t('Delete'),
                                },
                            ]}
                            visible={canDelete}
                        />
                    </Box>
                </Box>
            </Card.Header>

            <Card.Body>
                <Box
                    sx={{ alignItems: 'start', display: 'flex', gap: 1, pb: 1 }}
                >
                    {canModify && (
                        <Tooltip title={tooltipTitle}>
                            <IconButton onClick={onClickIcon}>
                                <CheckCircleIcon color={colorIcon} />
                            </IconButton>
                        </Tooltip>
                    )}

                    <Box
                        sx={{
                            inlineSize: '100%',
                            overflowWrap: 'break-word',
                            p: 0.5,
                        }}
                    >
                        <MentionsInput
                            style={{
                                ...mentionsMessageStyle,
                                input: {
                                    ...mentionsMessageStyle.input,
                                    color: isDarkMode ? '#fff' : '#000',
                                    fontSize: '0.9rem',
                                    textDecoration: completed
                                        ? 'line-through'
                                        : 'none',
                                },
                            }}
                            value={truncateString(description, maxCharacters)}
                        >
                            <Mention
                                data={getUsersOptions(users)}
                                style={
                                    isDarkMode
                                        ? {
                                              backgroundColor:
                                                  'rgba(255, 255, 255, 0.09)',
                                          }
                                        : mentionStyle
                                }
                            />
                        </MentionsInput>
                    </Box>

                    {description?.length > MAXIMUM_CHARACTERS_FOR_TRACKING && (
                        <Box textAlign="center">
                            <Button
                                color="secondary"
                                onClick={() =>
                                    maxCharacters >
                                    MAXIMUM_CHARACTERS_FOR_TRACKING
                                        ? setMaxCharacters(
                                              MAXIMUM_CHARACTERS_FOR_TRACKING,
                                          )
                                        : setMaxCharacters(description.length)
                                }
                                sx={{ p: 0 }}
                                variant="text"
                            >
                                {maxCharacters >
                                MAXIMUM_CHARACTERS_FOR_TRACKING ? (
                                    <ExpandLess />
                                ) : (
                                    <ExpandMore />
                                )}
                            </Button>
                        </Box>
                    )}
                </Box>

                <Typography
                    onClick={tooggleCommentArea}
                    fontWeight="bold"
                    sx={{
                        alignItems: 'center',
                        cursor: 'pointer',
                        display: 'flex',
                        gap: 1,
                        fontSize: '0.8rem',
                    }}
                    variant="body2"
                >
                    <CommentIcon sx={{ color: 'primary.main', fontSize: 16 }} />
                    {!isOpenCommentArea ? (
                        <>
                            {totalComments
                                ? `${t('Comment', { count: totalComments })} (${totalComments})`
                                : t('Add comment')}
                            <ExpandMore sx={{ fontSize: 16 }} />
                        </>
                    ) : (
                        <>
                            {t('Hide')} <ExpandLess sx={{ fontSize: 16 }} />
                        </>
                    )}
                </Typography>

                {isOpenCommentArea && (
                    <>
                        <CommentsList trackingId={id} />
                        <CommentForm trackingId={id} />
                    </>
                )}

                <Dialog
                    onClose={() => setIsOpenModalTracking(false)}
                    open={isOpenModalTracking}
                    size="sm"
                    title={t('Remember again')}
                >
                    <ReasignTrackingForm
                        callback={() => setIsOpenModalTracking(false)}
                        onSave={handleClickChangeDate}
                        trackingId={id}
                    />
                </Dialog>
            </Card.Body>
        </Card>
    );
};

TrackingItem.propTypes = {
    agent: PropTypes.object,
    assigned_to: PropTypes.array,
    canDelete: PropTypes.bool,
    canModify: PropTypes.bool,
    completed: PropTypes.bool,
    description: PropTypes.string,
    handleClickChangeDate: PropTypes.func,
    handleClickDelete: PropTypes.func,
    handleClickMarkAsCompleted: PropTypes.func,
    id: PropTypes.string,
    project: PropTypes.object,
    remember: PropTypes.bool,
    users: PropTypes.array,
    data: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
    profileValues: profileSelectors.getCurrentValues,
});

export default connect(mapStateToProps, null)(TrackingItem);
