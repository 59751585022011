import React from 'react';

import AddIcon from '@mui/icons-material/Add';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button } from 'sunwise-ui';

import Form from './Form';

const TrackingForm = ({
    canModify,
    dataContact,
    errors,
    fetchTrackingsPurposes,
    handleClickCancel,
    handleClickShowForm,
    handleClickSubmit,
    initialValues,
    isSaving,
    isVisibleForm,
    projectId,
    trackingsPurposes,
    trackingsPurposesToSelect,
    users,
}) => {
    const { t } = useTranslation();
    return (
        <>
            {!isVisibleForm ? (
                <Button
                    color="secondary"
                    endIcon={<AddIcon />}
                    fullWidth
                    onClick={handleClickShowForm}
                    variant="outlined"
                    visible={canModify}
                >
                    {t('Add follow-up')}
                </Button>
            ) : (
                <Form
                    dataContact={dataContact}
                    errors={errors}
                    fetchTrackingsPurposes={fetchTrackingsPurposes}
                    handleClickCancel={handleClickCancel}
                    initialValues={initialValues}
                    isSaving={isSaving}
                    onSubmit={handleClickSubmit}
                    projectId={projectId}
                    trackingsPurposes={trackingsPurposes}
                    trackingsPurposesToSelect={trackingsPurposesToSelect}
                    users={users}
                />
            )}
        </>
    );
};

TrackingForm.propTypes = {
    canModify: PropTypes.bool,
    dataContact: PropTypes.object,
    errors: PropTypes.array,
    fetchTrackingsPurposes: PropTypes.func,
    handleClickCancel: PropTypes.func,
    handleClickShowForm: PropTypes.func,
    handleClickSubmit: PropTypes.func,
    initialValues: PropTypes.object,
    isSaving: PropTypes.bool,
    isVisibleForm: PropTypes.bool,
    projectId: PropTypes.string,
    trackingsPurposes: PropTypes.object,
    trackingsPurposesToSelect: PropTypes.array,
    users: PropTypes.array,
};

export default TrackingForm;
