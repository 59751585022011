import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { FormControl, Grid, InputLabel, MenuItem, Select } from 'sunwise-ui';

import {
    FILTER_TYPE_CALL,
    FILTER_TYPE_MAIL,
    FILTER_TYPE_MESSAGE,
    FILTER_TYPE_OTHER,
    FILTER_TYPE_VISIT,
    ORDER_BY_NEXT,
    ORDER_BY_PAST,
} from '../constants';

const Filter = ({ filterData, setFilterData }) => {
    const { t } = useTranslation();
    const handleChange = (key, value) =>
        setFilterData((prev) => ({ ...prev, [key]: value }));

    const options = [
        { label: t('All text', { context: 'male', count: 2 }), value: 'all' },
        { label: t('Call'), value: FILTER_TYPE_CALL.toString() },
        { label: t('Mail'), value: FILTER_TYPE_MAIL.toString() },
        { label: t('Visit'), value: FILTER_TYPE_VISIT.toString() },
        { label: t('Message'), value: FILTER_TYPE_MESSAGE.toString() },
        { label: t('Other'), value: FILTER_TYPE_OTHER.toString() },
    ];

    const statusOptions = [
        { label: t('All text', { count: 2 }), value: 'all' },
        { label: t('Completed'), value: ORDER_BY_PAST.toString() },
        { label: t('Pending'), value: ORDER_BY_NEXT.toString() },
    ];

    return (
        <Grid container>
            <Grid size={{ xs: 18, sm: 9 }}>
                <FormControl fullWidth size="small">
                    <InputLabel>{t('Follow-up type')}</InputLabel>
                    <Select
                        label={t('Follow-up type')}
                        onChange={(e) => handleChange('type', e.target.value)}
                        value={filterData.type || 'all'}
                        variant="standard"
                    >
                        {options.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>

            <Grid size={{ xs: 18, sm: 9 }}>
                <FormControl fullWidth size="small">
                    <InputLabel>{t('Status')}</InputLabel>
                    <Select
                        label={t('Status')}
                        onChange={(e) => handleChange('status', e.target.value)}
                        value={filterData.status || 'all'}
                        variant="standard"
                    >
                        {statusOptions.map(({ label, value, disabled }) => (
                            <MenuItem
                                disabled={disabled}
                                key={`contact-type-select-${value}-${label}`}
                                value={value}
                            >
                                {label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
        </Grid>
    );
};

Filter.propTypes = {
    filterData: PropTypes.object,
    setFilterData: PropTypes.func,
};

export default Filter;
