import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Divider, FormControl, InputLabel, MenuItem, Select } from 'sunwise-ui';

import {
    FILTER_TYPE_CALL,
    FILTER_TYPE_MAIL,
    FILTER_TYPE_MESSAGE,
    FILTER_TYPE_OTHER,
    FILTER_TYPE_VISIT,
} from '../constants';

import PastTrackings from './PastTrackings';

const TrackingList = ({
    canDelete,
    canModify,
    handleClickChangeDate,
    handleClickDelete,
    handleClickFilter,
    handleClickUpdate,
    isLoading,
    session,
    trackings,
    users,
}) => {
    const { t } = useTranslation();

    const handleChange = (event) => handleClickFilter(event.target.value);

    const options = [
        { label: t('All text', { context: 'male', count: 2 }), value: 'ALL' },
        { label: t('Call'), value: FILTER_TYPE_CALL },
        { label: t('Mail'), value: FILTER_TYPE_MAIL },
        { label: t('Visit'), value: FILTER_TYPE_VISIT },
        { label: t('Message'), value: FILTER_TYPE_MESSAGE },
        { label: t('Other'), value: FILTER_TYPE_OTHER },
    ];

    return (
        <>
            <FormControl fullWidth>
                <InputLabel>{t('Filter by')}</InputLabel>
                <Select
                    label={t('Filter by')}
                    onChange={handleChange}
                    variant="standard"
                >
                    {options.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

            <Divider sx={{ my: 2 }} />

            <PastTrackings
                canDelete={canDelete}
                canModify={canModify}
                handleClickChangeDate={handleClickChangeDate}
                handleClickDelete={handleClickDelete}
                handleClickUpdate={handleClickUpdate}
                isLoading={isLoading}
                isPast
                session={session}
                trackings={trackings}
                users={users}
            />
        </>
    );
};

TrackingList.propTypes = {
    users: PropTypes.array,
    canDelete: PropTypes.bool,
    canModify: PropTypes.bool,
    handleClickChangeDate: PropTypes.func,
    handleClickDelete: PropTypes.func,
    handleClickFilter: PropTypes.func,
    handleClickUpdate: PropTypes.func,
    isLoading: PropTypes.bool,
    session: PropTypes.object,
    trackings: PropTypes.array,
};

export default TrackingList;
