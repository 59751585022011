import React, { useState, useEffect } from 'react';

import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';

import Logo from '../../layouts/defaultLayout/Logo';

const Container = styled('div')`
    align-items: center;
    background: ${({ mode }) => (mode === 'dark' ? '#000000' : '#002438')};
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    opacity: 1;
    overflow: hidden;
    position: fixed;
    top: 0;
    transition: all 0.2s linear;
    width: 100%;
    z-index: 10000;

    &.hide {
        opacity: 0;
    }
`;

const ImageContainer = styled('div')`
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 64px;
    justify-content: center;
    position: relative;
    width: 64px;

    img,
    svg {
        animation-duration: 8s;
        animation-iteration-count: infinite;
        animation-name: RotateAndFade;
        animation-timing-function: linear;
        width: 64px;
    }
`;

const DELAY = 250;

const FirstLoadView = ({ disabled, selectedTheme }) => {
    const [step, setStep] = useState(null);

    useEffect(() => {
        setStep(0);
    }, []);

    useEffect(() => {
        if (!disabled) {
            switch (step) {
                case 0: {
                    const timeoutFunc = () =>
                        setTimeout(() => setStep(1), DELAY);

                    if (document.readyState === 'complete') timeoutFunc();

                    window.addEventListener('load', timeoutFunc);

                    break;
                }
                case 1: {
                    setTimeout(() => {
                        setStep(2);
                    }, DELAY);
                    break;
                }
                case 2:
                    setTimeout(() => {
                        document.body.classList.remove('overflow-hidden');
                        setStep(3);
                    }, DELAY);
                    break;
                default:
            }
        }
    }, [step, setStep]);

    if (step === 3 || disabled) return null;

    return (
        <Container className={step > 1 && 'hide'} mode={selectedTheme}>
            <ImageContainer>
                <Logo field="loading_image" />
            </ImageContainer>
        </Container>
    );
};

FirstLoadView.propTypes = {
    disabled: PropTypes.bool,
    selectedTheme: PropTypes.string,
};

export default FirstLoadView;
