import { create, update } from 'common/api/v1/contactTrackingComments';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import {
    SAVE_COMMENT,
    SAVE_COMMENT_SUCCESS,
    SAVE_COMMENT_FAILURE,
} from '../actionTypes';
import { trackingsActions } from '../reducer';

import fetchComments from './fetchComments';

export default (values, callback) => (dispatch) => {
    const { contact_tracking } = values;

    const save = values.id
        ? () => update(values.id, values)
        : () => create(values);

    dispatch(trackingsActions[SAVE_COMMENT]());

    save()
        .then((response) => {
            dispatch(
                trackingsActions[SAVE_COMMENT_SUCCESS]({
                    ...response.data,
                    isNew: !values.id,
                }),
            );
            dispatch(fetchComments(contact_tracking));
            showToast();
            if (callback) callback();
        })
        .catch((error) => {
            dispatch(
                trackingsActions[SAVE_COMMENT_FAILURE](
                    error?.response?.data?.errors,
                ),
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
