import React, { useEffect, useState } from 'react';

import ArticleIcon from '@mui/icons-material/Article';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { Container as MaterialContainer } from 'sunwise-ui';

import ViewFilterHeader from 'common/components/ViewFilterHeader';
import { PERMISSION_LIST } from 'common/constants/permissionsV2';
import withPermissions from 'common/hocs/withPermissions';
import RestrictedAccessLanding from 'common/modules/restrictedAccessLanding/Container';
import { getCountryCurrencyLocale } from 'common/utils/helpers/session';

import * as credentialIntegrationsActions from '../credentialIntegrations/actions';

import * as actions from './actions';
import AppliedFilters from './components/AppliedFilters';
import Content from './components/Content';
import FiltersModal from './components/FiltersModal';
import Indicators from './components/Indicators';
import { FILTERS_KEYS, VIEWS } from './constants';
import { getFiltersFromQuery } from './helpers';
import * as selectors from './selectors';

const Container = ({
    downloadMonitoringCsv,
    fetchContacts,
    fetchIndicatorsData,
    fetchMonitoringData,
    fetchPoliticalDivisions,
    fetchProviders,
    fetchRates,
    initialValuesFilters,
    initializeFilters,
    location,
    reset,
    setIsOpenFiltersModal,
}) => {
    const [view, setView] = useState(VIEWS.MAP);
    const { t } = useTranslation();

    const { search: urlQuery = '' } = location;
    const countryCurrencyLocale = getCountryCurrencyLocale();

    useEffect(() => {
        fetchContacts();
        fetchIndicatorsData();
        fetchPoliticalDivisions('dp1');
        fetchProviders();
        fetchRates();

        () => reset();
    }, []);

    useEffect(() => {
        const filters = getFiltersFromQuery(urlQuery);

        const normalizedFilters = { ...filters };

        if (filters?.[FILTERS_KEYS.CUSTOM_RATE_ID]) {
            normalizedFilters[FILTERS_KEYS.RATE_ID] =
                normalizedFilters[FILTERS_KEYS.CUSTOM_RATE_ID];

            delete normalizedFilters[FILTERS_KEYS.CUSTOM_RATE_ID];
        }

        initializeFilters(normalizedFilters);
        fetchMonitoringData(filters);
    }, [urlQuery]);

    const handleOnChangeView = (newView) => {
        if (!newView) return;
        setView(newView);
    };

    const handleOpenFiltersModal = () => setIsOpenFiltersModal(true);

    return (
        <MaterialContainer maxWidth={false} height="100%">
            <ViewFilterHeader
                handleOnChangeView={handleOnChangeView}
                handleOpenFiltersModal={handleOpenFiltersModal}
                title={t('Monitoring')}
                titleIcon={<ArticleIcon color="primary" fontSize="large" />}
                view={view}
                viewTabs={[]}
            />

            <AppliedFilters
                downloadMonitoringCsv={(freq) =>
                    downloadMonitoringCsv({
                        ...getFiltersFromQuery(urlQuery),
                        [FILTERS_KEYS.FREQ]: freq,
                    })
                }
                filtersValues={initialValuesFilters}
            />

            <Indicators countryCurrencyLocale={countryCurrencyLocale} />

            <Content
                countryCurrencyLocale={countryCurrencyLocale}
                filtersValues={initialValuesFilters}
                sx={{ mt: 2 }}
                view={view}
            />

            <FiltersModal />
        </MaterialContainer>
    );
};

const mapStateToProps = createStructuredSelector({
    initialValuesFilters: selectors.getInitialValuesFilters,
});

const mapDispatchToProps = (dispatch) => ({
    downloadMonitoringCsv: (params) =>
        dispatch(actions.downloadMonitoringCsv(params)),
    fetchContacts: () => dispatch(actions.fetchContacts()),
    fetchIndicatorsData: () => dispatch(actions.fetchIndicatorsData()),
    fetchMonitoringData: (params) =>
        dispatch(actions.fetchMonitoringData(params)),
    fetchPoliticalDivisions: (str) =>
        dispatch(actions.fetchPoliticalDivisions(str)),
    fetchProviders: () =>
        dispatch(credentialIntegrationsActions.fetchProviders()),
    fetchRates: () => dispatch(actions.fetchRates()),
    initializeFilters: (params) => dispatch(actions.initializeFilters(params)),
    reset: () => dispatch(actions.reset()),
    setIsOpenFiltersModal: (isOpen) =>
        dispatch(actions.setIsOpenFiltersModal(isOpen)),
});

Container.propTypes = {
    downloadMonitoringCsv: PropTypes.func,
    fetchContacts: PropTypes.func,
    fetchIndicatorsData: PropTypes.func,
    fetchMonitoringData: PropTypes.func,
    fetchPoliticalDivisions: PropTypes.func,
    fetchProviders: PropTypes.func,
    fetchRates: PropTypes.func,
    initialValuesFilters: PropTypes.object,
    initializeFilters: PropTypes.func,
    location: PropTypes.object,
    reset: PropTypes.func,
    setIsOpenFiltersModal: PropTypes.func,
};

const RestrictedContainer = ({ canView, ...props }) => {
    if (!canView) return null;
    return (
        <RestrictedAccessLanding
            addonName="Monitoring-Dashboard,Sunwise-After-Sales"
            url="https://somos.sunwise.io/monitoreo-postventa"
        >
            <Container {...props} />
        </RestrictedAccessLanding>
    );
};

RestrictedContainer.propTypes = {
    canView: PropTypes.bool,
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withPermissions(PERMISSION_LIST.MONITORING_DASHBOARD_PERMISSION),
    withRouter,
)(RestrictedContainer);
