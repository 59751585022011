import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { Card, Typography } from 'sunwise-ui';

import parseQueryString from 'common/utils/parseQueryString';

import * as actions from '../../actions';
import * as selectors from '../../selectors';

import FormContainer from './FormContainer';
import List from './List';

const NotesContainer = ({ fetchNotes, location, notes }) => {
    const { t } = useTranslation();
    const { search: locationSearch = '' } = location;
    const { project: projectId } = parseQueryString(locationSearch);
    const [isFormVisible, setIsFormVisible] = useState(false);

    useEffect(() => {
        fetchNotes(projectId);
    }, [projectId]);

    return (
        <Card>
            <Card.Header>
                <Typography variant="body1" fontWeight="bold">
                    {t('Note', { count: 2 })}
                </Typography>
            </Card.Header>

            <Card.Body>
                <List items={notes} />

                <FormContainer
                    isFormVisible={isFormVisible}
                    projectId={projectId}
                    setIsFormVisible={setIsFormVisible}
                />
            </Card.Body>
        </Card>
    );
};

const mapStateToProps = createStructuredSelector({
    notes: selectors.getNotesData,
    isLoading: selectors.getNotesIsLoading,
});

const mapDispatchToProps = (dispatch) => ({
    fetchNotes: (projectId) => dispatch(actions.fetchNotes(projectId)),
});

NotesContainer.propTypes = {
    fetchNotes: PropTypes.func,
    location: PropTypes.object,
    notes: PropTypes.array,
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withRouter,
)(NotesContainer);
