import i18next from 'i18next';

import { deleteItem } from 'common/api/v1/contactTrackingComments';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import {
    DELETE_COMMENT,
    DELETE_COMMENT_FAILURE,
    DELETE_COMMENT_SUCCESS,
} from '../actionTypes';
import { trackingsActions } from '../reducer';

import fetchComments from './fetchComments';

export default (data) => (dispatch) => {
    const { commentId, trackingId } = data;
    if (!commentId || !trackingId) return;

    dispatch(trackingsActions[DELETE_COMMENT]());

    deleteItem(commentId)
        .then(() => {
            dispatch(trackingsActions[DELETE_COMMENT_SUCCESS](data));
            showToast({ body: i18next.t('Successfully deleted') });
            dispatch(fetchComments(trackingId));
        })
        .catch((error) => {
            dispatch(trackingsActions[DELETE_COMMENT_FAILURE]());
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
