import { push } from 'connected-react-router';

import { save } from 'common/api/v2/masterButton';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import { buildPayload as buildContactPayload } from '../../../../modules/contactForm/helpers';
import * as profileSelectors from '../../../../modules/profile/selectors';
import { SAVE, SAVE_SUCCESS, SAVE_FAILURE } from '../actionTypes';
import { actions } from '../reducer';

export default (values, callback, redirect = true) =>
    (dispatch, getState) => {
        const userId = profileSelectors.getUserId(getState());
        const { contact, tracking } = values;

        const contactValues = contact?.id
            ? contact
            : {
                  ...buildContactPayload(contact),
                  emails: contact.emails,
                  telephones: contact.telephones,
              };

        const trackingValues = {
            agent: userId,
            assigned_to: tracking?.assigned_to,
            contact: tracking?.contact,
            description: tracking?.description,
            notification_at: tracking?.notification_at?.concat(':00'),
            objective_tracking: tracking?.objective_tracking,
            project: tracking?.project,
            remember: tracking?.remember,
            tracking_purpose: tracking?.tracking_purpose,
            tracking_type: tracking?.tracking_type,
        };

        const newValues = {
            action: 'create-tracking',
            contact: contactValues,
            tracking: trackingValues,
        };

        dispatch(actions[SAVE]());

        save(newValues)
            .then((response) => {
                dispatch(actions[SAVE_SUCCESS](response.data));
                if (callback) callback();
                if (redirect) {
                    showToast();
                    const { contact } = response.data;
                    dispatch(push(`/record/${contact}?tab=information`));
                    return;
                }
            })
            .catch((error) => {
                dispatch(actions[SAVE_FAILURE](error?.response?.data?.errors));
                showReponseErrorsAsAlert(dispatch, error?.response);
            });
    };
