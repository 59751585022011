import i18next from 'i18next';

import alerts from 'common/modules/alerts';

import deleteNote from './deleteNote';

export default (data) => (dispatch) => {
    const { noteId, projectId } = data;
    if (!noteId || !projectId) return;

    const dataAlert = {
        confirmText: i18next.t('Delete'),
        messages: [i18next.t('Are you sure to delete the note?')],
        onSuccess: () => dispatch(deleteNote(data)),
        title: i18next.t('Delete commnent'),
        type: alerts.ALERT_TYPE_CONFIRM,
        variant: 'warning',
    };

    dispatch(alerts.actions.show(dataAlert));
};
