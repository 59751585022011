import { updateTracking } from 'common/api/v1/contactTrackings';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import {
    SAVE_TRACKING,
    SAVE_TRACKING_SUCCESS,
    SAVE_TRACKING_FAILURE,
} from '../actionTypes';
import { trackingsActions } from '../reducer';

import fetchTrackingsNext from './fetchTrackingsNext';

export default (id, values, filterData, callback) => (dispatch) => {
    const newValues = {
        completed: values.completed,
        notification_at: values.notification_at,
    };

    dispatch(trackingsActions[SAVE_TRACKING]());

    updateTracking(newValues, id)
        .then(() => {
            dispatch(trackingsActions[SAVE_TRACKING_SUCCESS]());
            dispatch(fetchTrackingsNext(filterData));
            showToast();
            if (callback) callback();
        })
        .catch((error) => {
            dispatch(
                trackingsActions[SAVE_TRACKING_FAILURE](
                    error?.response?.data?.errors,
                ),
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
