import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { Card, Grid, Typography } from 'sunwise-ui';

import { PERMISSION_LIST } from 'common/constants/permissionsV2';
import withPermissions from 'common/hocs/withPermissions';
import parseQueryString from 'common/utils/parseQueryString';

import projectDocument from '../../projectDocument';
import * as recordSelectors from '../../record/selectors';
import trackings from '../../trackings';

import NotesContainer from './NotesContainer';

const Container = ({ dataContact, match, location, getPermissionsByCode }) => {
    const { t } = useTranslation();
    const contactId = match?.params?.uid;
    const { search: locationSearch = '' } = location;
    const { project: projectId } = parseQueryString(locationSearch);

    const { canView: canViewTrackingContact } = getPermissionsByCode(
        PERMISSION_LIST.TRACKING_CONTACTS_PERMISSION,
    );

    return (
        <Grid container>
            <Grid size={{ md: canViewTrackingContact ? 9 : 18, xs: 18 }}>
                <projectDocument.Container projectId={projectId} />

                <NotesContainer />
            </Grid>

            {canViewTrackingContact && (
                <Grid size={{ md: 9, xs: 18 }}>
                    <Card>
                        <Card.Header>
                            <Typography variant="body1" fontWeight="bold">
                                {t('Follow-up', { count: 2 })}
                            </Typography>
                        </Card.Header>

                        <Card.Body>
                            <trackings.Container
                                contactId={contactId}
                                dataContact={dataContact}
                                isRecordTabView
                                projectId={projectId}
                            />
                        </Card.Body>
                    </Card>
                </Grid>
            )}
        </Grid>
    );
};

const mapStateToProps = createStructuredSelector({
    dataContact: recordSelectors.getDataContact,
});

const mapDispatchToProps = () => ({});

Container.propTypes = {
    dataContact: PropTypes.object,
    getPermissionsByCode: PropTypes.func,
    location: PropTypes.object,
    match: PropTypes.object,
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withPermissions([PERMISSION_LIST.TRACKING_CONTACTS_PERMISSION]),
    withRouter,
)(Container);
