import React, { useState, useEffect, useRef, useMemo } from 'react';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { get, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Alert, Button, Grid, Typography } from 'sunwise-ui';

import { ShowErrors } from 'common/components';
import { KAM_UID, SERFIMEX_UID } from 'common/constants';
import yupResolver from 'common/utils/yupResolver';

import * as contactActions from '../../../contactForm/actions';
import * as contactSelectors from '../../../contactForm/selectors';
import * as actions from '../../actions';
import financingAllianceValidate from '../../financingAllianceValidate';
import {
    getFinancierProducts,
    getLabelButton,
    getTypeBusinessOptions,
    initializeForm,
    updateErrorsAndAlerts,
    updateFormWithProduct,
} from '../../helpers';

import FormStep1 from './FormStep1';
import FormStep2 from './FormStep2';

const FormContainer = ({
    alliance,
    allianceProducts,
    canModifyContacts,
    contact,
    currencyIso,
    currencySymbol,
    fetchOfferDetails,
    initialValues,
    isLocked,
    isSaving,
    isSavingContact,
    proposalId,
    rateComplements,
    saveCiBanco,
    saveContact,
    total,
}) => {
    const { t } = useTranslation();
    const [productSelected, setProductSelected] = useState({});
    const [errorList, setErrorList] = useState([]);
    const [infoList, setInfoList] = useState([]);
    const hasInitialized = useRef(false);
    const prevProductSelected = useRef();

    const { control, handleSubmit, reset, setValue, watch } = useForm({
        context: {
            contact,
            parameters: get(
                productSelected,
                'financier_product_parameters',
                {},
            ),
            total,
        },
        defaultValues: initialValues,
        resolver: yupResolver(financingAllianceValidate),
    });

    useEffect(() => reset(initialValues), [initialValues]);

    const contactEmail = get(contact, 'email', null);
    const contactCompanyName = get(contact, 'company_name', null);

    const financierId = get(alliance, 'financier.id', null);
    const isKam = financierId === KAM_UID;
    const isSerfimex = financierId === SERFIMEX_UID;

    const formValues = watch();
    const step = get(formValues, 'step', 1);
    const typesBusinessCompany = get(formValues, 'types_business_company', '');
    const financierProduct = get(formValues, 'financier_product', ''); // Para errores y alertas
    const alerts = get(productSelected, 'alerts', {});
    const errors = get(productSelected, 'errors', []);
    const parameters = get(productSelected, 'financier_product_parameters', {});
    const annualIncrease = get(parameters, 'annual_increase', 0);
    const increasePercentage = get(rateComplements, 'increase_percentage', 0);

    const shouldSaveContact =
        isSerfimex && (isEmpty(contactEmail) || isEmpty(contactCompanyName));

    const typesBusinessOptions = useMemo(
        () => getTypeBusinessOptions(allianceProducts),
        [allianceProducts],
    );
    const financierProducts = useMemo(
        () => getFinancierProducts(allianceProducts, typesBusinessCompany),
        [allianceProducts, typesBusinessCompany],
    );

    // useEffect para inicialización
    useEffect(() => {
        if (!hasInitialized.current) {
            updateFormWithProduct(
                allianceProducts,
                typesBusinessOptions,
                setValue,
                setProductSelected,
            );
            hasInitialized.current = true;
        }
    }, [allianceProducts, typesBusinessOptions, setValue, setProductSelected]);

    // useEffect para actualizar el formulario con productSelected
    useEffect(() => {
        if (
            !isEmpty(productSelected) &&
            Object.keys(productSelected).length > 0 &&
            productSelected !== prevProductSelected.current
        ) {
            initializeForm(
                alliance,
                isSerfimex,
                productSelected,
                setValue,
                total,
                typesBusinessCompany,
            );
            prevProductSelected.current = productSelected;
        }
    }, [productSelected, isSerfimex, total, typesBusinessCompany, setValue]);

    // useEffect para errores y alertas
    useEffect(() => {
        updateErrorsAndAlerts(
            alerts,
            annualIncrease,
            errors,
            increasePercentage,
            setErrorList,
            setInfoList,
        );
    }, [
        alerts,
        annualIncrease,
        errors,
        increasePercentage,
        financierProduct, // Reinicia al cambiar producto
        setErrorList,
        setInfoList,
    ]);

    const handleOnClickSave = (values) => {
        switch (step) {
            case 1:
                if (shouldSaveContact) {
                    saveContact({ ...values, contactId: contact.id }, () => {
                        fetchOfferDetails(proposalId);
                        setValue('step', 2);
                    });
                    return false;
                } else {
                    setValue('step', 2);
                    return false;
                }
            case 2:
                return saveCiBanco(proposalId, values);
            default:
                return false;
        }
    };

    return (
        <form>
            <FormStep1
                allianceProducts={allianceProducts}
                canModifyContacts={canModifyContacts}
                contactCompanyName={contactCompanyName}
                contactEmail={contactEmail}
                control={control}
                financierProducts={financierProducts}
                isLocked={isLocked}
                isSerfimex={isSerfimex}
                productSelected={productSelected}
                resetErrors={() => {
                    setErrorList([]);
                    setInfoList([]);
                }}
                setProductSelected={setProductSelected}
                setValue={setValue}
                show={step === 1}
                typesBusinessOptions={typesBusinessOptions}
                typesBusinessCompany={typesBusinessCompany}
            />
            <FormStep2
                alliance={alliance}
                control={control}
                currencyIso={currencyIso}
                currencySymbol={currencySymbol}
                formValues={formValues}
                isKam={isKam}
                isSerfimex={isSerfimex}
                isLocked={isLocked}
                parameters={parameters}
                productSelected={productSelected}
                setValue={setValue}
                show={step === 2}
            />
            <Grid container>
                <Grid size={{ xs: 'grow' }}>
                    <ShowErrors errors={errorList} />
                    {!isEmpty(infoList) && (
                        <Alert severity="info">
                            {infoList.map((info, index) => (
                                <Typography variant="body2" key={index}>
                                    * {info}
                                </Typography>
                            ))}
                        </Alert>
                    )}
                </Grid>
            </Grid>
            <Grid container mt={1}>
                {isKam && (
                    <Grid size="grow">
                        <Button
                            color="secondary"
                            href={`${import.meta.env.VITE_ACADEMY_URL}es/articles/10746374-casos-de-uso-kam`}
                            sx={{ width: { md: 'auto', xs: '100%' } }}
                            target="_blank"
                            variant="text"
                        >
                            {t('See manual')}
                        </Button>
                    </Grid>
                )}
                <Grid
                    size="grow"
                    sx={{ textAlign: { xs: 'center', md: 'right' } }}
                >
                    <Button
                        disabled={
                            isLocked ||
                            isSavingContact ||
                            errorList.length > 0 ||
                            isEmpty(productSelected)
                        }
                        endIcon={<ArrowForwardIcon />}
                        onClick={handleSubmit(handleOnClickSave)}
                        sx={{ width: { xs: '100%', md: 'auto' } }}
                        type="button"
                    >
                        {getLabelButton({
                            isSaving: isSaving || isSavingContact,
                            step,
                        })}
                    </Button>
                </Grid>
            </Grid>
        </form>
    );
};

const mapStateToProps = createStructuredSelector({
    isSavingContact: contactSelectors.getIsSavingContact,
});

const mapDispatchToProps = (dispatch) => ({
    saveCiBanco: (id, values) => dispatch(actions.saveCiBanco(id, values)),
    saveContact: (values, callback) =>
        dispatch(contactActions.saveSomeContactData(values, callback)),
});

FormContainer.propTypes = {
    alliance: PropTypes.object,
    allianceProducts: PropTypes.array,
    canModifyContacts: PropTypes.bool,
    contact: PropTypes.object,
    currencyIso: PropTypes.string,
    currencySymbol: PropTypes.string,
    fetchOfferDetails: PropTypes.func,
    initialValues: PropTypes.object,
    isLocked: PropTypes.bool,
    isSaving: PropTypes.bool,
    isSavingContact: PropTypes.bool,
    proposalId: PropTypes.string,
    rateComplements: PropTypes.object,
    saveCiBanco: PropTypes.func,
    saveContact: PropTypes.func,
    total: PropTypes.number,
};

export default connect(mapStateToProps, mapDispatchToProps)(FormContainer);
