import React, { useEffect, useState } from 'react';

import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';

import ChartJs from 'common/components/ChartJs';
import { ChartControls, MonthSelector } from 'common/components/charts';
import { CHART_OPTIONS } from 'common/components/charts/ChartControls';

import { getChartConfig } from '../helpers';

const ChartComponent = ({ consumptionProfile, year = 2019 }) => {
    const [chartConfig, setChartConfig] = useState({
        data: [{ datasets: [], labels: [] }],
        options: {},
    });
    const [chartType, setChartType] = useState('area');
    const [selectedDays, setSelectedDays] = useState([1]);
    const [selectedMonth, setSelectedMonth] = useState(0);
    const [selectedOption, setSelectedOption] = useState(0);
    const [selectedWeeks, setSelectedWeeks] = useState([0]);

    const handleOnChange = (e) => {
        const value = parseInt(e.target.value);
        setSelectedOption(value);
        setSelectedMonth((prev) => (value === 1 && prev === 0 ? 1 : prev));
        setChartConfig(
            getChartConfig({
                consumptionProfile,
                days: selectedDays,
                month:
                    [CHART_OPTIONS.YEARLY, CHART_OPTIONS.MONTHLY].includes(
                        value,
                    ) && selectedMonth === 0
                        ? 1
                        : selectedMonth,
                type: value,
                weeks: selectedWeeks,
                year,
            }),
        );
    };

    const handleOnChangeSelectedMonth = (e) => {
        const value = parseInt(e.target.value);
        setSelectedMonth(value);
        setChartConfig(
            getChartConfig({
                consumptionProfile,
                days: selectedDays,
                month: value,
                type: selectedOption,
                weeks: selectedWeeks,
                year,
            }),
        );
    };

    const handleOnChangeSelectedWeeks = (e) => {
        let value = e.target.value;
        if (value[value.length - 1] === 'all')
            value = selectedWeeks.length === 4 ? [] : [0, 1, 2, 3];
        setSelectedWeeks(value);
        setChartConfig(
            getChartConfig({
                consumptionProfile,
                days: selectedDays,
                month: selectedMonth,
                type: selectedOption,
                weeks: value,
                year,
            }),
        );
    };

    const handleOnChangeSelectedDays = (e) => {
        let value = e.target.value;
        if (value[value.length - 1] === 'all')
            value = selectedDays.length === 7 ? [] : [0, 1, 2, 3, 4, 5, 6];
        setSelectedDays(value);
        setChartConfig(
            getChartConfig({
                consumptionProfile,
                days: value,
                month: selectedMonth,
                type: selectedOption,
                weeks: selectedWeeks,
                year,
            }),
        );
    };

    useEffect(() => {
        if (!isEmpty(consumptionProfile))
            setChartConfig(
                getChartConfig({
                    consumptionProfile,
                    days: selectedDays,
                    month: selectedMonth,
                    type: selectedOption,
                    weeks: selectedWeeks,
                    year,
                }),
            );
    }, [consumptionProfile, year]);

    if (isEmpty(consumptionProfile) || !chartConfig?.series?.length)
        return <></>;

    const data = {
        datasets: chartConfig.series.map(({ data, name }) => ({
            data,
            label: name,
        })),
        labels: chartConfig.options.xaxis.categories,
    };

    const yLabel = selectedOption === CHART_OPTIONS.DAILY ? '%kW' : '%kWh';

    const options = {
        interaction: { axis: 'x', intersect: false, mode: 'nearest' },
        scales: {
            x: { grid: { display: false } },
            y: {
                ticks: { format: { maximumFractionDigits: 4 } },
                title: { display: true, text: yLabel },
            },
        },
    };

    const type = chartType === 'area' ? 'line' : chartType;

    return (
        <>
            <ChartControls
                chartType={chartType}
                chartTypeOptions={['area', 'bar']}
                handleOnChangePeriod={handleOnChange}
                handleOnChangeSelectedDays={handleOnChangeSelectedDays}
                handleOnChangeSelectedWeeks={handleOnChangeSelectedWeeks}
                selectedDays={selectedDays}
                selectedOption={selectedOption}
                selectedWeeks={selectedWeeks}
                setChartType={setChartType}
            />

            <ChartJs data={data} height="350px" options={options} type={type} />

            {[
                CHART_OPTIONS.DAILY,
                CHART_OPTIONS.MONTHLY,
                CHART_OPTIONS.WEEKLY,
                CHART_OPTIONS.WEEKLY_BY_HOUR,
            ].includes(selectedOption) && (
                <MonthSelector
                    onChange={handleOnChangeSelectedMonth}
                    showYearlyOption={[
                        CHART_OPTIONS.DAILY,
                        CHART_OPTIONS.WEEKLY,
                        CHART_OPTIONS.WEEKLY_BY_HOUR,
                    ].includes(selectedOption)}
                    value={selectedMonth}
                />
            )}
        </>
    );
};

ChartComponent.propTypes = {
    consumptionProfile: PropTypes.object,
    year: PropTypes.number,
};

export default ChartComponent;
