import { createSlice } from '@reduxjs/toolkit';

import {
    DELETE_PROJECT,
    DELETE_PROJECT_FAILURE,
    DELETE_PROJECT_SUCCESS,
    DELETE_NOTE,
    DELETE_NOTE_FAILURE,
    DELETE_NOTE_SUCCESS,
    FETCH_NOTES,
    FETCH_NOTES_FAILURE,
    FETCH_NOTES_SUCCESS,
    FETCH_PROJECT_DETAILS,
    FETCH_PROJECT_DETAILS_FAILURE,
    FETCH_PROJECT_DETAILS_SUCCESS,
    FETCH_PROJECTS,
    FETCH_PROJECTS_FAILURE,
    FETCH_PROJECTS_SUCCESS,
    FETCH_STATUS_PROJECT,
    FETCH_STATUS_PROJECT_FAILURE,
    FETCH_STATUS_PROJECT_SUCCESS,
    RENAME_PROJECT,
    RENAME_PROJECT_FAILURE,
    RENAME_PROJECT_SUCCESS,
    RESET_VALUES,
    SAVE_NOTE,
    SAVE_NOTE_FAILURE,
    SAVE_NOTE_SUCCESS,
    UPDATE_STATUS_PROJECT_SUCCESS,
} from './actionTypes';
import { NAME } from './constants';

const INITIAL_STATE = {
    contactId: null,
    deleteProject: { errors: [], isDeleting: false },
    deleteNote: { errors: [], isDeleting: false },
    fetchProject: { data: {}, errors: [], isFetching: false },
    notes: { data: [], errors: [], isLoading: false },
    projects: { data: [], errors: [], isFetching: false },
    renameProject: { data: {}, errors: [], isLoading: false },
    saveNote: { errors: [], isSaving: false },
    statusProject: { data: [], error: null, isEmpty: false, isLoading: false },
};

const projectSlice = createSlice({
    name: NAME,
    initialState: INITIAL_STATE,
    reducers: {
        [DELETE_PROJECT]: (state) => {
            state.deleteProject.errors = [];
            state.deleteProject.isDeleting = true;
        },
        [DELETE_PROJECT_FAILURE]: (state, action) => {
            state.deleteProject.errors = action.payload;
            state.deleteProject.isDeleting = false;
        },
        [DELETE_PROJECT_SUCCESS]: (state) => {
            state.deleteProject.isDeleting = false;
        },
        [DELETE_NOTE]: (state) => {
            state.deleteNote.errors = [];
            state.deleteNote.isDeleting = true;
        },
        [DELETE_NOTE_FAILURE]: (state, action) => {
            state.deleteNote.errors = action.payload;
            state.deleteNote.isDeleting = false;
        },
        [DELETE_NOTE_SUCCESS]: (state) => {
            state.deleteNote.isDeleting = false;
        },
        [FETCH_PROJECT_DETAILS]: (state) => {
            state.fetchProject = {
                ...state.fetchProject,
                errors: [],
                isFetching: true,
            };
        },
        [FETCH_PROJECT_DETAILS_FAILURE]: (state, action) => {
            state.fetchProject = {
                ...state.fetchProject,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_PROJECT_DETAILS_SUCCESS]: (state, action) => {
            state.fetchProject = {
                ...state.fetchProject,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_NOTES]: (state) => {
            state.notes.errors = [];
            state.notes.isLoading = true;
        },
        [FETCH_NOTES_FAILURE]: (state, action) => {
            state.notes.errors = action.payload;
            state.notes.isLoading = false;
        },
        [FETCH_NOTES_SUCCESS]: (state, action) => {
            state.notes.data = action.payload;
            state.notes.isLoading = false;
        },
        [FETCH_PROJECTS]: (state) => {
            state.projects.errors = [];
            state.projects.isFetching = true;
        },
        [FETCH_PROJECTS_FAILURE]: (state, action) => {
            state.projects.errors = action.payload;
            state.projects.isFetching = false;
        },
        [FETCH_PROJECTS_SUCCESS]: (state, action) => {
            state.projects.data = action.payload;
            state.projects.isFetching = false;
        },
        [FETCH_STATUS_PROJECT]: (state) => {
            state.statusProject.isLoading = true;
        },
        [FETCH_STATUS_PROJECT_FAILURE]: (state, action) => {
            state.statusProject.error = action.payload;
            state.statusProject.isLoading = false;
        },
        [FETCH_STATUS_PROJECT_SUCCESS]: (state, action) => {
            state.statusProject.data = action.payload;
            state.statusProject.isEmpty = action.payload.length === 0;
            state.statusProject.isLoading = false;
        },
        [RENAME_PROJECT]: (state) => {
            state.renameProject.isLoading = true;
        },
        [RENAME_PROJECT_FAILURE]: (state, action) => {
            state.renameProject.errors = action.payload;
            state.renameProject.isLoading = false;
        },
        [RENAME_PROJECT_SUCCESS]: (state, action) => {
            state.renameProject.data = action.payload;
            state.renameProject.isLoading = false;
        },
        [RESET_VALUES]: () => INITIAL_STATE,
        [SAVE_NOTE]: (state) => {
            state.saveNote.errors = [];
            state.saveNote.isSaving = true;
        },
        [SAVE_NOTE_FAILURE]: (state, action) => {
            state.saveNote.errors = action.payload;
            state.saveNote.isSaving = false;
        },
        [SAVE_NOTE_SUCCESS]: (state) => {
            state.saveNote.isSaving = false;
        },
        [UPDATE_STATUS_PROJECT_SUCCESS]: (state, action) => {
            const index = state.projects.data?.findIndex(
                (project) => project.id === action.payload.projectId,
            );

            if (index >= 0) {
                const statusIndex = state.statusProject.data.findIndex(
                    (statusOption) =>
                        statusOption.id === action.payload.statusProject,
                );

                state.projects.data[index].status_project =
                    state.statusProject.data[statusIndex];
            }
        },
    },
});

export const projectActions = projectSlice.actions;

export default projectSlice.reducer;
