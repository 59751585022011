import i18next from 'i18next';
import { createSelector } from 'reselect';

import { NAME } from './constants';

export const getModel = (state) => state[NAME];

export const getIsOpenModal = createSelector(
    getModel,
    (model) => model.isOpenModal,
);

export const getProjectDetails = createSelector(
    getModel,
    (model) => model.fetchProject,
);

export const getIsFetchingProjectDetails = createSelector(
    getProjectDetails,
    (value) => value.isFetching,
);

export const getProjectDetailsData = createSelector(
    getProjectDetails,
    (value) => value.data,
);

export const getProjectHasAfterSales = createSelector(
    getProjectDetailsData,
    (project) => project?.has_aftersales,
);

export const getProjects = createSelector(getModel, (model) => model.projects);

export const getIsFetchingProjects = createSelector(
    getProjects,
    (projects) => projects.isFetching,
);

export const getDataProjects = createSelector(
    getProjects,
    (projects) => projects.data,
);

export const getStatusProject = createSelector(
    getModel,
    (model) => model.statusProject,
);

export const getIsLoadingStatusProject = createSelector(
    getStatusProject,
    (statusProjects) => statusProjects.isLoading,
);

export const getStatusProjectDictionary = createSelector(
    getStatusProject,
    (statusProjects) => {
        if (!statusProjects.data) {
            return {};
        }
        return statusProjects.data.reduce((acc, current) => {
            return { ...acc, [current.id]: current };
        }, {});
    },
);

export const getStatusProjectData = createSelector(
    getStatusProject,
    (statusProjects) => statusProjects.data || [],
);

export const getStatusProjectToSelect = createSelector(
    getStatusProjectData,
    (statusProjectsData) => {
        return [
            {
                color: 'transparent',
                disabled: true,
                key: '',
                label: i18next.t('Select status'),
                value: '',
            },
            ...statusProjectsData.map((statusProject) => ({
                key: statusProject.id,
                value: statusProject.name,
                label: statusProject.name,
                color: statusProject.primary_color,
            })),
        ];
    },
);

export const getStatusProjectToSelectForFilterView = createSelector(
    getStatusProjectData,
    (statusProjectsData) => {
        return [
            {
                value: '',
                label: i18next.t('All text', { context: 'male', count: 2 }),
            },
            {
                value: 'NO_STATUS_LIST',
                label: i18next.t('No status'),
                color: '#EFF1FB',
            },
            ...statusProjectsData.map((statusProject) => ({
                value: statusProject.id,
                label: statusProject.name,
                color: statusProject.primary_color,
            })),
        ];
    },
);

// Notes selectors
export const getNotesModel = createSelector(getModel, (model) => model.notes);

export const getNotesData = createSelector(
    getNotesModel,
    (model) => model.data,
);

export const getNotesError = createSelector(
    getNotesModel,
    (model) => model.error,
);

export const getNotesIsLoading = createSelector(
    getNotesModel,
    (model) => model.isLoading,
);

export const getSaveNoteModel = createSelector(
    getModel,
    (model) => model.saveNote,
);

export const getSaveNoteIsSaving = createSelector(
    getSaveNoteModel,
    (model) => model.isSaving,
);
