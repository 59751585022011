import React, { useEffect, useState } from 'react';

import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { Box, Divider, Typography } from 'sunwise-ui';

import { PERMISSION_LIST } from 'common/constants/permissionsV2';
import withPermissions from 'common/hocs/withPermissions';

import * as actions from './actions';
import { NextTrackings, TrackingForm } from './components';
import Filter from './components/Filter';
import * as selectors from './selectors';

const Container = ({
    canDelete,
    canModify,
    canView,
    contactId,
    dataContact,
    errors,
    fetchTrackingsNext,
    fetchTrackingsPurposes,
    fetchUsers,
    initialValues,
    isLoadingTrackingNext,
    isSaving,
    isVisibleForm,
    markTrackingAsCompleted,
    prepareCreateForm,
    prepareDelete,
    prepareUpdateForm,
    projectId,
    isRecordTabView,
    rescheduleTracking,
    save,
    setContactId,
    showForm,
    trackings,
    trackingsPurposes,
    trackingsPurposesToSelect,
    users,
}) => {
    const { t } = useTranslation();
    const [filterData, setFilterData] = useState({
        isRecordTabView,
        status: 'all',
        type: 'all',
    });

    useEffect(() => {
        setContactId(contactId);
        fetchUsers();
    }, []);

    useEffect(() => {
        fetchTrackingsNext({ ...filterData, projectId });
    }, [filterData, projectId]);

    const handleClickSubmit = (values) => save(values, filterData);

    const handleClickMarkAsCompleted = (id) =>
        markTrackingAsCompleted(id, filterData);

    const handleClickChangeDate = (id, values, callback) =>
        rescheduleTracking(id, values, filterData, callback);

    const handleClickDelete = (tracking) => prepareDelete(tracking, filterData);

    const trackingsMap = trackings.map((tracking) => {
        let trackingTemp = { ...tracking };
        dataContact &&
            (trackingTemp.agent = tracking.agent
                ? tracking.agent
                : dataContact.agent);
        return trackingTemp;
    });

    const handleClickCancel = () => showForm(false);

    if (!canView) return null;

    return (
        <>
            {!isRecordTabView && (
                <Box display="flex" py={2} gap={2}>
                    <NotificationsActiveIcon />
                    <Box display="flex" flexDirection="column">
                        <Typography variant="body2" fontWeight="bold">
                            {t(
                                'A well-informed prospect will be an assured customer',
                            )}
                        </Typography>
                        <Typography variant="body2">
                            {t(
                                'Remember that Sunwise allows you to schedule your next follow-ups and will notify you on the day and time you should do it',
                            )}
                        </Typography>
                    </Box>
                </Box>
            )}

            <TrackingForm
                canModify={canModify}
                dataContact={dataContact}
                errors={errors}
                fetchTrackingsPurposes={fetchTrackingsPurposes}
                handleClickCancel={handleClickCancel}
                handleClickShowForm={prepareCreateForm}
                handleClickSubmit={handleClickSubmit}
                initialValues={initialValues}
                isSaving={isSaving}
                isVisibleForm={isVisibleForm}
                projectId={projectId}
                trackingsPurposes={trackingsPurposes}
                trackingsPurposesToSelect={trackingsPurposesToSelect}
                users={users}
            />

            <Divider sx={{ my: 2 }} />

            <Filter filterData={filterData} setFilterData={setFilterData} />

            <Divider sx={{ my: 2 }} />

            <NextTrackings
                canDelete={canDelete}
                canModify={canModify}
                handleClickChangeDate={handleClickChangeDate}
                handleClickDelete={handleClickDelete}
                handleClickMarkAsCompleted={handleClickMarkAsCompleted}
                handleClickUpdate={prepareUpdateForm}
                isLoading={isLoadingTrackingNext}
                trackings={trackingsMap}
                users={users}
            />
        </>
    );
};

const mapStateToProps = createStructuredSelector({
    dataConfirmModal: selectors.getDataConfirmModal,
    errors: selectors.getErrors,
    filterTypeTrackings: selectors.getFilterTypeTrackings,
    initialValues: selectors.getInitialValues,
    isLoadingTrackingNext: selectors.getIsLoadingTrackingsNext,
    isLoadingTrackings: selectors.getIsLoadingTrackings,
    isOpenConfirmModal: selectors.getIsOpenConfirmModal,
    isSaving: selectors.getIsSavingTracking,
    isVisibleForm: selectors.getIsVisibleForm,
    trackings: selectors.getDataTrackingsNext,
    trackingsPurposes: selectors.getTrackingPurpose,
    trackingsPurposesToSelect: selectors.getTrackingPurposeToSelect,
    trackingsTemp: selectors.getDataTrackings,
    users: selectors.getUsersData,
});

const mapDispatchToProps = (dispatch) => ({
    dropTracking: (id) => dispatch(actions.dropTracking(id)),
    fetchTrackingsNext: (filterData) =>
        dispatch(actions.fetchTrackingsNext(filterData)),
    fetchTrackingsPurposes: () => dispatch(actions.fetchTrackingsPurposes()),
    fetchUsers: () => dispatch(actions.fetchUsers()),
    rescheduleTracking: (id, values, filterData, callback) =>
        dispatch(actions.rescheduleTracking(id, values, filterData, callback)),
    markTrackingAsCompleted: (id, filterData) =>
        dispatch(actions.markTrackingAsCompleted(id, filterData)),
    prepareCreateForm: () => dispatch(actions.prepareCreateForm()),
    prepareDelete: (element, filterData) =>
        dispatch(actions.prepareDelete(element, filterData)),
    prepareUpdateForm: (id) => dispatch(actions.prepareUpdateForm(id)),
    save: (values, filterData) => dispatch(actions.save(values, filterData)),
    setContactId: (id) => dispatch(actions.setContactId(id)),
    showForm: (value) => dispatch(actions.showForm(value)),
    openConfirmModal: (value) => dispatch(actions.openConfirmModal(value)),
});

Container.propTypes = {
    canDelete: PropTypes.bool,
    canModify: PropTypes.bool,
    canView: PropTypes.bool,
    contactId: PropTypes.string,
    dataContact: PropTypes.object,
    errors: PropTypes.array,
    fetchTrackingsNext: PropTypes.func,
    fetchTrackingsPurposes: PropTypes.func,
    fetchUsers: PropTypes.func,
    initialValues: PropTypes.object,
    isLoadingTrackingNext: PropTypes.bool,
    isRecordTabView: PropTypes.bool,
    isSaving: PropTypes.bool,
    isVisibleForm: PropTypes.bool,
    markTrackingAsCompleted: PropTypes.func,
    prepareCreateForm: PropTypes.func,
    prepareDelete: PropTypes.func,
    prepareUpdateForm: PropTypes.func,
    projectId: PropTypes.string,
    rescheduleTracking: PropTypes.func,
    save: PropTypes.func,
    setContactId: PropTypes.func,
    showForm: PropTypes.func,
    trackings: PropTypes.array,
    trackingsPurposes: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    trackingsPurposesToSelect: PropTypes.array,
    users: PropTypes.array,
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withPermissions(PERMISSION_LIST.TRACKING_CONTACTS_PERMISSION),
)(Container);
