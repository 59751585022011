export const determineTab = (
    canViewContactProjects,
    canViewTrackingContact,
    showInformationTab,
    tab,
) => {
    switch (tab) {
        case 'projects':
            return canViewContactProjects ? 'projects' : 'information';
        case 'information':
        case 'tracking':
            return showInformationTab || canViewTrackingContact
                ? 'information'
                : 'none';
        case 'record':
            return 'record';
        default:
            return 'none';
    }
};

export const determineType = (canViewProposals, canViewSummaries, type) => {
    switch (type) {
        case 'proposals':
            return canViewProposals ? 'proposals' : 'summaries';
        case 'summaries':
            return canViewSummaries ? 'summaries' : 'none';
        default:
            return 'none';
    }
};

export const determineView = ({
    canViewMonitoring,
    canViewReports,
    showProposalsTab,
    tabView,
}) => {
    switch (tabView) {
        case 'proposals':
            return showProposalsTab ? 'proposals' : 'monitoring';
        case 'monitoring':
            return canViewMonitoring ? 'monitoring' : 'reports';
        case 'reports':
            return canViewReports ? 'reports' : 'none';
        //TODO: add permissions for notes
        case 'record':
            return 'record';
        default:
            return 'none';
    }
};

export const isValidParam = (param) => param !== 'none';
