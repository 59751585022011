import i18next from 'i18next';

import { deleteTracking } from 'common/api/v1/contactTrackings';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import {
    DELETE_TRACKING,
    DELETE_TRACKING_FAILURE,
    DELETE_TRACKING_SUCCESS,
} from '../actionTypes';
import { trackingsActions } from '../reducer';

import fetchTrackingsNext from './fetchTrackingsNext';

export default (id, filterData) => (dispatch) => {
    dispatch(trackingsActions[DELETE_TRACKING]());

    deleteTracking(id)
        .then(() => {
            dispatch(trackingsActions[DELETE_TRACKING_SUCCESS]());
            dispatch(fetchTrackingsNext(filterData));
            showToast({ body: i18next.t('It was successfully removed') });
        })
        .catch((error) => {
            dispatch(
                trackingsActions[DELETE_TRACKING_FAILURE](
                    error?.response?.data?.errors,
                ),
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
