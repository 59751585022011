import React from 'react';

import { Alert } from '@sunwisesoftware/sunwise-ui';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import {
    getHasBagEnergyField,
    handleOnChangeEnergyFields,
} from '../../helpers';
import * as selectors from '../../selectors';

import FormBottomActions from './FormBottomActions';
import PeriodFields from './PeriodFields';

const EnergyStep = ({
    availableBag,
    compensationScheme,
    control,
    disabled,
    disabledNext,
    energyBalanceErrors,
    getValues,
    handleChangeAvailableBag,
    handleClickBack,
    handleClickNext,
    rate,
    setEnergyBalanceErrors,
    setValue,
}) => {
    const { t } = useTranslation();

    const handleOnChangePeriodField = (key) => {
        handleOnChangeEnergyFields({
            getValues,
            key,
            setEnergyBalanceErrors,
            setValue,
        });

        const hasBagEnergyField = getHasBagEnergyField(
            rate,
            compensationScheme,
        );

        if (hasBagEnergyField) handleChangeAvailableBag(availableBag);
    };

    return (
        <>
            <PeriodFields
                compensationScheme={compensationScheme}
                control={control}
                disabled={disabled}
                energyBalanceErrors={energyBalanceErrors}
                handleOnChangePeriodField={handleOnChangePeriodField}
            />

            {energyBalanceErrors?.hasErrors && (
                <Alert severity="error">
                    {t(
                        'There are errors in the energy balance. Please review it',
                    )}
                </Alert>
            )}

            <FormBottomActions
                disabled={disabled || disabledNext}
                handleClickBack={handleClickBack}
                handleClickNext={handleClickNext}
                nextText={t('Save')}
                type="submit"
            />
        </>
    );
};

const mapStateToProps = createStructuredSelector({
    availableBag: selectors.getAvailableBag,
});

EnergyStep.propTypes = {
    availableBag: PropTypes.number,
    compensationScheme: PropTypes.string,
    control: PropTypes.object,
    disabled: PropTypes.bool,
    disabledNext: PropTypes.bool,
    energyBalanceErrors: PropTypes.object,
    getValues: PropTypes.func,
    handleChangeAvailableBag: PropTypes.func,
    handleClickBack: PropTypes.func,
    handleClickNext: PropTypes.func,
    rate: PropTypes.object,
    setEnergyBalanceErrors: PropTypes.func,
    setValue: PropTypes.func,
};

export default connect(mapStateToProps)(EnergyStep);
