import React from 'react';

import PropTypes from 'prop-types';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box, Grid, Typography } from 'sunwise-ui';

import { GROUPED_ENERGY_FIELDS } from '../../constants';

import EnergyField from './EnergyField';
import EnergyFieldsByTier from './EnergyFieldsByTier';
import FormAccordion from './FormAccordion';

const PeriodFields = ({
    compensationScheme,
    control,
    disabled: disabledFields,
    energyBalanceErrors,
    handleOnChangePeriodField,
}) => {
    const fieldsKeys = useWatch({ control, name: 'fields_keys' });
    const { t } = useTranslation();

    return GROUPED_ENERGY_FIELDS.map((group) => {
        const isNetMetGroup =
            group.onlyNetmet &&
            group.compensationSchemes?.includes(compensationScheme);
        const shouldShowFields = !group.compensationSchemes || isNetMetGroup;

        if (!shouldShowFields) return null;

        return (
            <FormAccordion
                defaultExpanded={group.defaultExpanded}
                key={group.label}
                title={t(group.label)}
            >
                {group.fields.map(({ disabled, key, label }) => (
                    <Box key={key}>
                        <Typography mb={1} variant="subtitle2">
                            {t(label)}
                        </Typography>

                        <Grid container>
                            {fieldsKeys?.energy?.length > 0 ? (
                                <EnergyFieldsByTier
                                    concept={key}
                                    control={control}
                                    disabled={disabled || disabledFields}
                                    errors={energyBalanceErrors?.errors}
                                    fieldsKeys={fieldsKeys.energy}
                                    handleOnChangePeriodField={
                                        handleOnChangePeriodField
                                    }
                                />
                            ) : (
                                <EnergyField
                                    concept={key}
                                    control={control}
                                    disabled={disabled || disabledFields}
                                    name={`total.${key}`}
                                    error={
                                        energyBalanceErrors?.errors?.total?.[
                                            key
                                        ]
                                    }
                                    onBlur={() => handleOnChangePeriodField()}
                                />
                            )}
                        </Grid>
                    </Box>
                ))}
            </FormAccordion>
        );
    });
};

PeriodFields.propTypes = {
    compensationScheme: PropTypes.string,
    control: PropTypes.object,
    disabled: PropTypes.bool,
    energyBalanceErrors: PropTypes.object,
    handleOnChangePeriodField: PropTypes.func,
};

export default PeriodFields;
