import React from 'react';

import BusinessIcon from '@mui/icons-material/Business';
import EmailIcon from '@mui/icons-material/Email';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import PhoneIcon from '@mui/icons-material/Phone';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import PropTypes from 'prop-types';

const TrackingTypeIcon = ({ sx, type }) => {
    switch (type) {
        case 1:
            return <EmailIcon sx={{ color: 'primary.main', ...sx }} />;
        case 2:
            return <BusinessIcon sx={{ color: '#785f9d', ...sx }} />;
        case 3:
            return <WhatsAppIcon sx={{ color: '#02e675', ...sx }} />;
        case 4:
            return <LibraryBooksIcon sx={{ color: '#74ebff', ...sx }} />;

        default:
            return <PhoneIcon sx={{ color: '#008dff', ...sx }} />;
    }
};

TrackingTypeIcon.propTypes = {
    sx: PropTypes.object,
    type: PropTypes.number,
};

export default TrackingTypeIcon;
