import React, { useEffect, useRef, useState } from 'react';

import isEmpty from 'lodash/isEmpty';
import papa from 'papaparse';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Button, Grid, ToggleButton, ToggleButtonGroup } from 'sunwise-ui';

import ChartJs from 'common/components/ChartJs';
import {
    DEFAULT_INITIAL_DATE,
    DEFAULT_MONTH_FINAL_DATE,
    DEBOUNCE_TIME_500,
} from 'common/constants';
import { useBreakpoint, useDebounce } from 'common/hooks';
import { differenceInDaysDate, parseDate } from 'common/utils/dates';
import { getCurrencyIso } from 'common/utils/helpers';
import {
    getCompensationHasNettedExportedGeneration,
    getIsSelfConsumption,
} from 'common/utils/helpers/rates';
import {
    buildChartJsProps,
    getProposalChartConfigBag,
    getProposalChartConfigEconomic,
    handleProposalEnergyChartConfig,
} from 'common/utils/helpersChart';

import ChartControls from './ChartControls';
import { rebuildOptions, rebuildData } from './helpers';
import PeriodSelector from './PeriodSelector';

const CHART_BASE_CONFIG = {
    ENERGY: { label: 'Energetic', name: 'chartEnergy' },
    CURRENT_ECONOMIC: {
        label: 'Economical',
        name: 'chartCurrentEconomic',
        visibilityLabel: 'Current economic scenario',
    },
    PROPOSED_ECONOMIC: {
        name: 'chartProposedEconomic',
        visibilityLabel: 'Proposed economic scenario',
    },
    PROPOSED_ENERGY_BAG: {
        name: 'chartProposedEnergyBag',
        visibilityLabel: 'Proposed energy pool',
    },
};
const INFO_KEYS = {
    [CHART_BASE_CONFIG.CURRENT_ECONOMIC.name]: {
        infoKey: 'infoPriceConsumption',
        stack: 'current',
    },
    [CHART_BASE_CONFIG.PROPOSED_ECONOMIC.name]: {
        infoKey: 'infoPriceGeneration',
        stack: 'proposed',
    },
};
const tabsToShow = {
    ENERGY: CHART_BASE_CONFIG.ENERGY,
    CURRENT_ECONOMIC: CHART_BASE_CONFIG.CURRENT_ECONOMIC,
};
const stacksToSelect = [
    CHART_BASE_CONFIG.CURRENT_ECONOMIC,
    CHART_BASE_CONFIG.PROPOSED_ECONOMIC,
    CHART_BASE_CONFIG.PROPOSED_ENERGY_BAG,
];

const ProposalChartJs = ({
    consumptionProjection,
    countryCurrency,
    countryCurrencyLocale,
    initialChartControlsConfig = {},
    proposalConfiguration,
    solarSimulationData,
}) => {
    const { t } = useTranslation();
    const [chartConfigEnergy, setChartConfigEnergy] = useState({
        data: { datasets: [], labels: '' },
        key: 'energy-chart-NETMET',
        options: {},
        series: [],
    });
    const [chartConfigEconomic, setChartConfigEconomic] = useState({
        data: { datasets: [], labels: '' },
        options: {},
        series: [],
    });
    const [chartId, setChartValue] = useState(CHART_BASE_CONFIG.ENERGY.name);
    const [chartControlsConfig, setChartControlsConfig] = useState({
        days: [1],
        option: 0,
        period: 0,
        totalWeeks: 0,
        type: 'area',
        weeks: [0],
        year: 0,
        ...initialChartControlsConfig,
    });
    const [defaultVisibility, setDefaultVisibility] = useState({});
    const [tooltipConfig, setTooltipConfig] = useState({ view: 'all' });
    const [selectedStacks, setSelectedStacks] = useState('all');
    const breakpoint = useBreakpoint();
    const chartRef = useRef(null);
    const container = useRef(null);
    const countryCurrencyIso = getCurrencyIso(countryCurrency);
    const debouncedConsumptionProjectionData = useDebounce(
        consumptionProjection,
        DEBOUNCE_TIME_500,
    );
    const hasNettedExportedGeneration =
        getCompensationHasNettedExportedGeneration(
            proposalConfiguration?.compensation_scheme,
        );
    const isSelfConsumption = getIsSelfConsumption(
        proposalConfiguration?.compensation_scheme,
    );

    const {
        consumptionHistory = [],
        realIndex = {},
        zeroExport,
    } = debouncedConsumptionProjectionData;

    useEffect(() => {
        if (!isEmpty(initialChartControlsConfig)) {
            const totalWeeks = getTotalWeeksInPeriod(
                initialChartControlsConfig?.period ??
                    chartControlsConfig?.period,
            );

            const newChartControlsConfig = {
                ...chartControlsConfig,
                ...initialChartControlsConfig,
                totalWeeks,
            };
            setChartControlsConfig(newChartControlsConfig);
            handleChartChange(newChartControlsConfig);
        }
    }, [initialChartControlsConfig]);

    useEffect(() => {
        handleChartChange(chartControlsConfig);
    }, [solarSimulationData]);

    useEffect(() => {
        if (consumptionHistory?.length) {
            const newYear =
                chartControlsConfig?.year < consumptionHistory?.length
                    ? chartControlsConfig?.year
                    : 0;

            const newChartControlsConfig = {
                ...chartControlsConfig,
                year: newYear || 0,
            };

            setChartControlsConfig(newChartControlsConfig);
            handleChartChangeEconomic({
                selectedStacks,
                year: chartControlsConfig.year,
            });
            handleChartChange(newChartControlsConfig);
        }
    }, [consumptionHistory]);

    useEffect(() => {
        if (chartId === CHART_BASE_CONFIG.ENERGY.name) {
            handleChartChange(chartControlsConfig);
        } else {
            handleChartChangeEconomic({
                selectedStacks,
                year: chartControlsConfig.year,
            });
        }
    }, [defaultVisibility, tooltipConfig]);

    const getTotalWeeksInPeriod = (period) => {
        const finalDate = consumptionHistory?.[0]?.[period]?.final_date;
        const initialDate = consumptionHistory?.[0]?.[period]?.initial_date;
        const diff = differenceInDaysDate(
            parseDate(finalDate || DEFAULT_MONTH_FINAL_DATE, 'dd/MM/yyyy'),
            parseDate(initialDate || DEFAULT_INITIAL_DATE, 'dd/MM/yyyy'),
        );

        return Math.max(Math.ceil(diff / 7), 0) || 0;
    };

    const handleChartChange = (configOptions) => {
        const totalWeeks = getTotalWeeksInPeriod(configOptions.period);
        setChartControlsConfig({ ...configOptions, totalWeeks });

        const newChartConfigEnergy = handleProposalEnergyChartConfig({
            configOptions,
            consumptionHistory,
            countryCurrencyLocale,
            hasNettedExportedGeneration:
                hasNettedExportedGeneration && configOptions.option === 0,
            isSelfConsumption,
            realIndex,
            solarSimulationData,
            zeroExport,
        });

        const newDataChartConfigEnergy = buildChartJsProps({
            chartControlsConfig: configOptions,
            chartId: CHART_BASE_CONFIG.ENERGY.name,
            countryCurrencyLocale,
            currentChartConfig: newChartConfigEnergy,
            defaultVisibility,
            handleOnLegendClick,
            isSelfConsumption,
            tooltipConfig,
        });

        setChartConfigEnergy(newDataChartConfigEnergy);
    };

    const handleChartChangeEconomic = ({ selectedStacks = 'all', year }) => {
        const yearCategories = consumptionHistory[year]?.map(
            (month) => month.label,
        );

        if (selectedStacks === CHART_BASE_CONFIG.PROPOSED_ENERGY_BAG.name) {
            const newChartConfigBag = getProposalChartConfigBag({
                consumptionHistory: consumptionHistory[year],
                yearCategories,
            });

            const newDataChartConfigBag = buildChartJsProps({
                chartControlsConfig,
                chartId,
                countryCurrencyIso,
                countryCurrencyLocale,
                currentChartConfig: newChartConfigBag,
                defaultVisibility,
                handleOnLegendClick,
                isSelfConsumption,
                selectedStacks,
                tooltipConfig,
            });

            setChartConfigEconomic(newDataChartConfigBag);
            return;
        }

        const fixedChartId =
            selectedStacks === 'all'
                ? CHART_BASE_CONFIG.CURRENT_ECONOMIC.name
                : selectedStacks;

        let fixedSeries = [];
        let options = {};

        const stacksToShow =
            selectedStacks === 'all'
                ? [
                      INFO_KEYS[CHART_BASE_CONFIG.CURRENT_ECONOMIC.name],
                      INFO_KEYS[CHART_BASE_CONFIG.PROPOSED_ECONOMIC.name],
                  ]
                : [INFO_KEYS[fixedChartId]];

        for (const stackToShow of stacksToShow) {
            const { series, ...rest } = getProposalChartConfigEconomic({
                consumptionHistory: consumptionHistory[year],
                countryCurrencyIso,
                countryCurrencyLocale,
                infoKey: stackToShow.infoKey,
                yearCategories,
            });

            fixedSeries.push(
                ...series.map((serie) => ({
                    ...serie,
                    stack: stackToShow.stack,
                })),
            );

            options = rest;
        }

        const newDataChartConfigEconomic = buildChartJsProps({
            chartControlsConfig,
            chartId: fixedChartId,
            countryCurrencyIso,
            countryCurrencyLocale,
            currentChartConfig: { ...options, series: fixedSeries },
            defaultVisibility,
            handleOnLegendClick,
            isSelfConsumption,
            selectedStacks,
            tooltipConfig,
        });

        setChartConfigEconomic(newDataChartConfigEconomic);
    };

    const handleOnChangeSelectedYear = (e) => {
        const value = parseInt(e.target.value);
        const newChartControlsConfig = { ...chartControlsConfig, year: value };

        setChartControlsConfig(newChartControlsConfig);
        handleChartChange(newChartControlsConfig);
        handleChartChangeEconomic({ selectedStacks, year: value });
    };

    const handleOnChangeOption = (e) => {
        const value = parseInt(e.target.value);
        let newChartControlsConfig = { ...chartControlsConfig, option: value };

        if ([2, 3].includes(value))
            newChartControlsConfig.totalWeeks = getTotalWeeksInPeriod(
                chartControlsConfig.period,
            );

        setChartControlsConfig(newChartControlsConfig);
        handleChartChange(newChartControlsConfig);
    };

    const handleOnChangeSelectedPeriod = (e) => {
        const value = parseInt(e.target.value);
        let newChartControlsConfig = { ...chartControlsConfig, period: value };

        if ([2, 3].includes(chartControlsConfig.option))
            newChartControlsConfig.totalWeeks = getTotalWeeksInPeriod(value);

        setChartControlsConfig(newChartControlsConfig);
        handleChartChange(newChartControlsConfig);
    };

    const handleOnChangeSelectedWeeks = (e) => {
        let value = e.target.value;
        if (value[value.length - 1] === 'all') {
            const { totalWeeks, weeks } = chartControlsConfig;
            value =
                weeks.length === totalWeeks
                    ? []
                    : [...Array(totalWeeks).keys()];
        }

        const newChartControlsConfig = { ...chartControlsConfig, weeks: value };

        setChartControlsConfig(newChartControlsConfig);
        handleChartChange(newChartControlsConfig);
    };

    const handleOnChangeSelectedDays = (e) => {
        let value = e.target.value;
        if (value[value.length - 1] === 'all')
            value =
                chartControlsConfig.days.length === 7
                    ? []
                    : [0, 1, 2, 3, 4, 5, 6];

        const newChartControlsConfig = { ...chartControlsConfig, days: value };

        setChartControlsConfig(newChartControlsConfig);
        handleChartChange(newChartControlsConfig);
    };

    const handleOnChangeChartType = (value) => {
        const newChartControlsConfig = { ...chartControlsConfig, type: value };
        setChartControlsConfig(newChartControlsConfig);
        handleChartChange(newChartControlsConfig);
    };

    const handleOnChangeVisibility = (values) => {
        if (!chartId) return;
        setDefaultVisibility((prev) => ({ ...prev, [chartId]: values }));
    };

    const chartConfig = {
        [CHART_BASE_CONFIG.CURRENT_ECONOMIC.name]: chartConfigEconomic,
        [CHART_BASE_CONFIG.ENERGY.name]: chartConfigEnergy,
    };

    const currentChartConfig = chartConfig[chartId];

    const isDefaultEnergyChart =
        !isSelfConsumption && chartControlsConfig.option === 0;
    const showPeriodSelectors =
        !isEmpty(solarSimulationData) &&
        chartId === CHART_BASE_CONFIG.ENERGY.name;
    const showStackSelector =
        chartId === CHART_BASE_CONFIG.CURRENT_ECONOMIC.name;

    const handleOnLegendClick = ({ label, series = [] }) => {
        setDefaultVisibility((prev) => {
            const currentSeries =
                prev[chartId] ?? (series ?? []).map((serie) => serie.label);
            const isCurrentlyVisible = currentSeries.includes(label);
            return {
                ...prev,
                [chartId]: isCurrentlyVisible
                    ? currentSeries.filter((index) => index !== label)
                    : [...currentSeries, label],
            };
        });
    };
    const onChange = (e) => setChartValue(e.target.value);

    const getfilteredSeries = (series) => {
        if (isEmpty(series)) return [];
        let uniqueLabels = {};
        for (const serie of series) {
            if (!uniqueLabels[serie.name]) uniqueLabels[serie.name] = serie;
        }
        return Object.values(uniqueLabels);
    };

    const chartType = chartControlsConfig.type;
    const chartTypeOptions = ['area', 'bar'];
    const defaultVisibilityValue = defaultVisibility[chartId];
    const hideChangeTypeButtons = isDefaultEnergyChart || !showPeriodSelectors;
    const orientation = ['xs', 'sm', 'md'].includes(breakpoint)
        ? 'vertical'
        : 'horizontal';

    const { finalData, finalSeries } = rebuildData({
        chartControlsConfig,
        chartId,
        consumptionProjection,
        data: currentChartConfig?.data,
        proposalConfiguration,
        series: currentChartConfig?.series,
    });

    const { finalOptions } = rebuildOptions({
        currentChartConfig,
        tooltipConfig,
    });

    const filteredSeries = getfilteredSeries(finalSeries);

    const setChartType = (type) => handleOnChangeChartType(type);

    const handleSelectedStacks = (stacks) => {
        setSelectedStacks(stacks);
        handleChartChangeEconomic({
            selectedStacks: stacks,
            year: chartControlsConfig.year,
        });
    };

    return (
        <Box mt={2} ref={container}>
            <Button
                onClick={() => {
                    if (!solarSimulationData?.debugData) return;
                    const csvDataBlob = new Blob(
                        [papa.unparse(solarSimulationData.debugData)],
                        { type: 'text/csv;charset=utf-8;' },
                    );
                    const csvUrl = URL.createObjectURL(csvDataBlob);
                    const filename = 'simulation.csv';
                    const downloadLink = document.createElement('a');
                    downloadLink.setAttribute('href', csvUrl);
                    downloadLink.setAttribute('download', filename);
                    downloadLink.style.visibility = 'hidden';
                    document.body.appendChild(downloadLink);
                    downloadLink.click();
                    document.body.removeChild(downloadLink);
                }}
                sx={{ m: 2 }}
            >
                {t('Download CSV')}
            </Button>

            <Grid container justifyContent="center" mb={2}>
                <Grid size={{ md: 14, xs: 18 }}>
                    <ToggleButtonGroup
                        color="primary"
                        exclusive
                        fullWidth
                        onChange={onChange}
                        orientation={orientation}
                        size="small"
                        value={chartId}
                    >
                        {Object.values(tabsToShow).map(({ label, name }) => (
                            <ToggleButton key={name} value={name}>
                                {t(label)}
                            </ToggleButton>
                        ))}
                    </ToggleButtonGroup>
                </Grid>
            </Grid>

            <ChartControls
                chartRef={chartRef}
                chartType={chartType}
                chartTypeOptions={chartTypeOptions}
                consumptionHistory={
                    consumptionHistory[chartControlsConfig.year]
                }
                datasets={finalSeries}
                defaultVisibility={defaultVisibilityValue}
                handleOnChangePeriod={handleOnChangeOption}
                handleOnChangeSelectedDays={handleOnChangeSelectedDays}
                handleOnChangeSelectedPeriod={handleOnChangeSelectedPeriod}
                handleOnChangeSelectedWeeks={handleOnChangeSelectedWeeks}
                hideChangeTypeButtons={hideChangeTypeButtons}
                onChangeVisibility={handleOnChangeVisibility}
                selectedDays={chartControlsConfig.days}
                selectedOption={chartControlsConfig.option}
                selectedPeriod={chartControlsConfig.period}
                selectedStacks={selectedStacks}
                selectedWeeks={chartControlsConfig.weeks}
                series={filteredSeries}
                setChartType={setChartType}
                setSelectedStacks={handleSelectedStacks}
                setTooltipConfig={setTooltipConfig}
                showPeriodSelectors={showPeriodSelectors}
                showStackSelector={showStackSelector}
                showVisibilityControls
                stacksToSelect={stacksToSelect}
                tooltipConfig={tooltipConfig}
                weeksToSelect={chartControlsConfig.totalWeeks}
            />

            {currentChartConfig && (
                <ChartJs
                    key={`chartId-${chartId}-${chartControlsConfig.option}`}
                    chartRef={chartRef}
                    data={finalData}
                    height="350px"
                    options={finalOptions}
                    type={currentChartConfig?.options?.type}
                />
            )}

            <Grid container justifyContent="center" my={1}>
                <Grid size={{ xs: 'grow' }}>
                    <PeriodSelector
                        consumptionHistory={consumptionHistory}
                        onChange={handleOnChangeSelectedYear}
                        isYearSelector
                        value={chartControlsConfig.year}
                    />
                </Grid>
            </Grid>
        </Box>
    );
};

ProposalChartJs.propTypes = {
    consumptionProjection: PropTypes.object,
    countryCurrency: PropTypes.object,
    countryCurrencyLocale: PropTypes.string,
    initialChartControlsConfig: PropTypes.object,
    proposalConfiguration: PropTypes.object,
    solarSimulationData: PropTypes.object,
};

export default ProposalChartJs;
