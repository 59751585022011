import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Box, Divider } from 'sunwise-ui';

import * as actions from '../actions';
import * as selectors from '../selectors';

import Comment from './Comment';

const Comments = ({ comments, fetchComments, trackingId }) => {
    const { t } = useTranslation();

    useEffect(() => {
        fetchComments(trackingId);
    }, [trackingId]);

    const filteredComments = comments?.[trackingId] || [];

    if (filteredComments?.length === 0) {
        return (
            <>
                <Box sx={{ fontSize: '0.8rem', my: 2 }}>{t('No comments')}</Box>
                <Divider sx={{ my: 2 }} />
            </>
        );
    }

    const reversedComments = [...filteredComments].reverse();

    return reversedComments?.map((data) => (
        <Comment key={`comment-${data.id}`} sx={{ mt: 2 }} data={data} />
    ));
};

Comments.propTypes = {
    comments: PropTypes.object,
    fetchComments: PropTypes.func,
    trackingId: PropTypes.string,
};

const mapStateToProps = createStructuredSelector({
    comments: selectors.getCommentsData,
});

const mapDispatchToProps = (dispatch) => ({
    fetchComments: (id) => dispatch(actions.fetchComments(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Comments);
