import i18next from 'i18next';

import alerts from 'common/modules/alerts';

import deleteComment from './deleteComment';

export default (data) => (dispatch) => {
    const { commentId, trackingId } = data;
    if (!commentId || !trackingId) return;

    const dataAlert = {
        confirmText: i18next.t('Delete'),
        messages: [i18next.t('Are you sure to delete the comment?')],
        onSuccess: () => dispatch(deleteComment(data)),
        title: i18next.t('Delete commnent'),
        type: alerts.ALERT_TYPE_CONFIRM,
        variant: 'warning',
    };

    dispatch(alerts.actions.show(dataAlert));
};
