import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';

import SourceInfoChip from '../SourceInfoChip';

const ControlledInfoChip = ({ control, disabled, name, fromFinancing }) => (
    <Controller
        name={name}
        control={control}
        render={({ field }) => {
            useEffect(() => {
                if (fromFinancing) {
                    // Si es "generation", activarlo; si no, desactivarlo.
                    field.onChange(name === 'generation');
                }
            }, [fromFinancing]); // Se ejecuta solo cuando cambia fromFinancing.

            return (
                <SourceInfoChip
                    active={field.value} // Muestra el estado real del chip.
                    disabled={disabled} // Respeta la propiedad disabled.
                    infoKey={name}
                    onClick={() => !disabled && field.onChange(!field.value)}
                    onDelete={() => !disabled && field.onChange(false)}
                />
            );
        }}
    />
);

ControlledInfoChip.propTypes = {
    control: PropTypes.object.isRequired,
    disabled: PropTypes.bool,
    name: PropTypes.string.isRequired,
    fromFinancing: PropTypes.bool,
};

export default ControlledInfoChip;
