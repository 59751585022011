import get from 'lodash/get';

import { getItems } from 'common/api/v2/projectsComments';

import {
    FETCH_NOTES,
    FETCH_NOTES_FAILURE,
    FETCH_NOTES_SUCCESS,
} from '../actionTypes';
import { projectActions } from '../reducer';

export default (projectId, callback) => (dispatch) => {
    dispatch(projectActions[FETCH_NOTES]());
    getItems(projectId)
        .then((response) => {
            const data = get(response, 'data.data', []);
            dispatch(projectActions[FETCH_NOTES_SUCCESS](data));
            if (callback) callback(data);
        })
        .catch((error) => dispatch(projectActions[FETCH_NOTES_FAILURE](error)));
};
