import i18next from 'i18next';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

export const getOptionsToSelect = ({
    data,
    isNewRecord,
    label,
    planData,
    prefix,
}) => {
    let items = data.map((item) => ({
        label: item.name,
        value: item.id,
    }));
    const item = get(planData, prefix, {});
    if (!isNewRecord && ['archived', 'deleted'].includes(item?.status_flag)) {
        items = [
            ...items,
            {
                disabled: true,
                label: item.name,
                value: item.id,
            },
        ];
    }
    return [{ label, value: '', disabled: true }, ...items];
};

export const getOptionsToSelectForRHFArray = ({
    data,
    isNewRecord,
    label,
    parentFieldName,
    planData,
    prefix,
}) => {
    let items = data.map((item) => {
        const brand_name = item?.[parentFieldName]?.brand?.name || '';
        return {
            brand_name,
            label: item.name,
            value: item.id,
        };
    });
    const array = get(planData, prefix, []);
    array.forEach((item) => {
        if (
            !isNewRecord &&
            ['archived', 'deleted'].includes(item?.status_flag)
        ) {
            items = [
                ...items,
                { disabled: true, label: item.name, value: item.id },
            ];
        }
    });
    return [{ disabled: true, label, value: '' }, ...items];
};

export const getTemplatesToSelect = (templates, isNewRecord, planData) => {
    let items = templates.map((item) => ({
        label: item.title,
        value: item.external_uuid,
    }));
    const template = get(planData, 'custom_offer_template', {});
    if (
        !isNewRecord &&
        ['archived', 'deleted'].includes(template?.status_flag)
    ) {
        items = [
            ...items,
            {
                disabled: true,
                label: template.title,
                value: template.external_uuid,
            },
        ];
    }
    return [
        { label: i18next.t('Select a template'), value: '', disabled: true },
        ...items,
    ];
};

const mapItems = (items) => {
    if (isEmpty(items)) return [{ item: '' }];
    return items.map((item) => ({ item: item.id }));
};

const handleLists = (list) => {
    const temp = {};
    if (list) list.forEach((item) => (temp[item] = true));
    return temp;
};

export const buildValues = (item, disabled = false) => ({
    accessories: mapItems(item.accessories_branch_office),
    additionals: mapItems(item.additionals_branch_office),
    apply_desired_power_factor: item.desired_power_factor_enabled,
    battery: get(item, ['battery_branch_office', 'id'], ''),
    branch_office: get(item, ['branch_office', 'id'], ''),
    considerations: item.considerations,
    cost_ranges_kwh: item.cost_ranges_kwh,
    cost_ranges_watt: item.cost_ranges_watt,
    costing_type: item.costing_type || 'by_product',
    currency: get(item, ['currency', 'id'], ''),
    deprecated: item.pre_2018_enabled,
    desired_power_factor: item.desired_power_factor,
    disabled,
    external_custom_template_id: get(item, ['external_custom_template_id'], ''),
    generation_delay: item?.start_in_months?.toString(),
    guaranteed_gen_active: item.guaranteed_gen_active || false,
    guaranteed_gen: item.guaranteed_gen,
    id: item.id,
    installation_type: handleLists(item.installation_type),
    inverter_brand: get(item, ['inverter_brand', 'id'], ''),
    isr_percentage: item.tax_incentive_percentage,
    isr_simulation: item.isr_simulation,
    isr_simulation_type: item.isr_simulation_type,
    isr: item.tax_incentive_enabled,
    name: item.name,
    opex_active: item.opex_active,
    opex_cost_ranges_kw: item.opex_cost_ranges_kw,
    opex_cost_ranges_kwh: item.opex_cost_ranges_kwh,
    opex_costing_type: item.opex_costing_type || 'by_fixed_amount',
    opex_percentage_increase: item.opex_percentage,
    opex_periodicity: item.opex_periodicity,
    opex: item.opex,
    panel: get(item, ['panel_branch_office', 'id'], ''),
    ppa_active: item.ppa_active,
    ppa_price: item.ppa_price,
    saving_percentage: item.saving_percentage,
    simulation_years: item.simulation_years,
    structures: mapItems(item.structures),
    validity: item.validity,
    workforce_and_electric_materials: mapItems(
        item.workforce_and_electric_materials_branch_office,
    ),
});
