import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Typography } from 'sunwise-ui';

import TrackingItem from './TrackingItem';

const NextTrackings = ({
    canDelete,
    canModify,
    handleClickChangeDate,
    handleClickDelete,
    handleClickMarkAsCompleted,
    handleClickUpdate,
    isLoading,
    trackings,
    users,
}) => {
    const { t } = useTranslation();

    if (isLoading) return null;

    if (!trackings || trackings.length === 0) {
        return (
            <Typography sx={{ p: 2, textAlign: 'center' }}>
                {t('No items')}
            </Typography>
        );
    }

    return trackings.map((tracking) => (
        <TrackingItem
            canDelete={canDelete}
            canModify={canModify}
            data={tracking}
            handleClickDelete={() => handleClickDelete(tracking)}
            handleClickMarkAsCompleted={() =>
                handleClickMarkAsCompleted(tracking.id)
            }
            handleClickUpdate={() => handleClickUpdate(tracking.id)}
            handleClickChangeDate={handleClickChangeDate}
            key={`tracking-item-${tracking.id}`}
            users={users}
        />
    ));
};

NextTrackings.propTypes = {
    canDelete: PropTypes.bool,
    canModify: PropTypes.bool,
    handleClickChangeDate: PropTypes.func,
    handleClickDelete: PropTypes.func,
    handleClickMarkAsCompleted: PropTypes.func,
    handleClickUpdate: PropTypes.func,
    isLoading: PropTypes.bool,
    trackings: PropTypes.array,
    users: PropTypes.array,
};

export default NextTrackings;
