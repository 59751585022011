import i18next from 'i18next';

import { DAYS_IN_WEEK, HOURS_IN_DAY } from 'common/constants';
import { parseDate } from 'common/utils/dates';
import { numberFormat } from 'common/utils/helpers';
import { normalizeCsvDataToHourlyArray } from 'common/utils/helpers/csv';
import {
    createFilledArray,
    getChartCategories,
    getDataProfileFormatted,
    getDaysByWeek,
    getGradient,
    MONTHS_ARRAY,
} from 'common/utils/helpersChart';

import { CHART_OPTIONS } from '../../common/components/charts/ChartControls';

import { COLORS } from './constants';

const getDataMonthlyChart = (generationProfile, monthSelected, series) => {
    const monthData = generationProfile[monthSelected];
    const totalDays = Object.values(monthData).length;
    let newSeries = [];

    for (const source of series) {
        let monthlyData = [];

        const _total = source.data[monthSelected - 1] * totalDays;
        const factor = _total / monthData.total;

        for (const day in monthData) {
            const dayData = monthData[day];

            if (!dayData?.total) continue;

            monthlyData.push(dayData.total * factor);
        }

        newSeries.push({ data: monthlyData, name: source.name });
    }
    return newSeries;
};

const getWeeklyData = (generationProfile, months, series, weeks) => {
    const daysInMonth = getDaysByWeek(weeks);
    const newSeries = [];
    const weeklyData = createFilledArray(DAYS_IN_WEEK, 0);

    for (const month of months) {
        if (!generationProfile[month]) continue;

        for (const day in daysInMonth) {
            if (!generationProfile[month][day]) continue;

            const dayType = generationProfile[month][day].day;

            const fixedDayType = dayType === 0 ? 6 : dayType - 1;

            weeklyData[fixedDayType] += generationProfile[month][day].total;
        }
    }

    const multiplier = months.length * months.length * weeks.length;

    for (const source of series) {
        const sunHoursFC = source.data.reduce((acc, curr, index) => {
            const monthProfile = generationProfile[index + 1];

            const totalDays = Object.values(monthProfile).length - 1;

            const factor = totalDays / multiplier / monthProfile.total;

            return months.includes(index + 1) ? acc + curr * factor || 0 : acc;
        }, 0);

        newSeries.push({
            data: weeklyData.map((value) => value * sunHoursFC),
            name: source.name,
        });
    }
    return newSeries;
};

const getWeeklyByHourData = ({ generationProfile, months, series, weeks }) => {
    const maxDays = months.reduce((acc, curr) => {
        const monthDays = Object.keys(generationProfile[curr]);
        return Math.max(acc, monthDays.length - 1);
    }, 0);

    const daysInMonth = getDaysByWeek(weeks, maxDays);
    const dailyData = createFilledArray(daysInMonth.length * HOURS_IN_DAY, 0);
    const newSeries = [];

    for (const month of months) {
        for (let dayIndex = 0; dayIndex < daysInMonth.length; dayIndex++) {
            const day = daysInMonth[dayIndex];
            if (!generationProfile[month][day]) continue;

            for (let hour = 0; hour < HOURS_IN_DAY; hour++) {
                const index = dayIndex * HOURS_IN_DAY + Number(hour);

                dailyData[index] += generationProfile[month][day][hour] || 0;
            }
        }
    }

    const multiplier = months.length * months.length;

    for (const source of series) {
        const sunHoursFC = source.data.reduce((acc, curr, index) => {
            const monthProfile = generationProfile[index + 1];
            const totalDays = Object.values(monthProfile).length - 1;

            const factor = totalDays / multiplier / monthProfile.total;

            return months.includes(index + 1) ? acc + curr * factor || 0 : acc;
        }, 0);

        newSeries.push({
            data: dailyData.map((value) => value * sunHoursFC),
            name: source.name,
        });
    }
    return newSeries;
};

const getDailyData = (generationProfile, months, days, series, weeks) => {
    const dailyData = createFilledArray(HOURS_IN_DAY, 0);
    const daysInMonth = getDaysByWeek(weeks);
    const newSeries = [];

    for (const month of months) {
        for (const day of daysInMonth) {
            if (!generationProfile[month][day]) continue;

            if (!days.includes(generationProfile[month]?.[day]?.day)) continue;

            for (let hour = 0; hour <= 23; hour++)
                dailyData[hour] += generationProfile[month][day][hour] || 0;
        }
    }

    const multiplier =
        days.length * months.length * months.length * weeks.length;

    for (const source of series) {
        const sunHoursFC = source.data.reduce((acc, curr, index) => {
            const monthProfile = generationProfile[index + 1];
            const totalDays = Object.values(monthProfile).length - 1;

            const factor = totalDays / multiplier / monthProfile.total;

            return months.includes(index + 1) ? acc + curr * factor || 0 : acc;
        }, 0);

        newSeries.push({
            data: dailyData.map((value) => value * sunHoursFC),
            name: source.name,
        });
    }
    return newSeries;
};

const getChartSeries = ({
    days,
    generationProfile,
    month,
    series,
    type,
    weeks,
}) => {
    if (!generationProfile) return series;
    const isAnnualType = month === 0;
    switch (type) {
        case CHART_OPTIONS.YEARLY:
            return series;
        case CHART_OPTIONS.MONTHLY:
            return getDataMonthlyChart(generationProfile, month, series);
        case CHART_OPTIONS.WEEKLY:
            return getWeeklyData(
                generationProfile,
                isAnnualType ? MONTHS_ARRAY : [month],
                series,
                weeks,
            );
        case CHART_OPTIONS.WEEKLY_BY_HOUR:
            return getWeeklyByHourData({
                generationProfile,
                months: isAnnualType ? MONTHS_ARRAY : [month],
                series,
                weeks,
            });
        case CHART_OPTIONS.DAILY:
            return getDailyData(
                generationProfile,
                isAnnualType ? MONTHS_ARRAY : [month],
                days,
                series,
                weeks,
            );
    }
};

// EXPORTS
export const getAvg = (values) => {
    if (!values || !Array.isArray(values)) return 0;

    const total = values.reduce((acc, curr) => acc + parseFloat(curr.value), 0);
    if (!total) return 0;

    return (total / values.length).toFixed(2);
};

export const getChartConfig = ({
    countryCurrencyLocale,
    days,
    generationProfile,
    month,
    series,
    type,
    weeks,
    year,
} = {}) => {
    const chartSeries = getChartSeries({
        days,
        generationProfile,
        month,
        series,
        type,
        weeks,
    });

    const labels = getChartCategories({
        month,
        series: chartSeries,
        type,
        weeks,
        year,
    });

    const data = {
        datasets: chartSeries.map(({ data, name: label }) => {
            const color = COLORS[label]?.backgroundColor || '#002438';
            const order = COLORS[label]?.order || 6;
            return {
                backgroundColor: (ctx) => getGradient({ color, context: ctx }),
                borderColor: color,
                data,
                label,
                order,
            };
        }),
        labels,
    };

    const yLabel = [CHART_OPTIONS.DAILY, CHART_OPTIONS.WEEKLY_BY_HOUR].includes(
        type,
    )
        ? 'kW'
        : 'kWh';

    const options = {
        interaction: { intersect: false, mode: 'nearest' },
        plugins: {
            tooltip: {
                callbacks: {
                    label: (context) => {
                        return (
                            context.dataset.label +
                            ': ' +
                            numberFormat(context.parsed.y, {
                                locale: countryCurrencyLocale,
                                style: 'decimal',
                                unit: yLabel,
                            })
                        );
                    },
                },
                mode: 'index',
            },
        },
        scales: {
            x: { grid: { display: false } },
            y: {
                ticks: {
                    callback: (value) =>
                        numberFormat(value, {
                            locale: countryCurrencyLocale,
                            style: 'decimal',
                        }),
                },
                title: { display: true, text: `${yLabel} / kWp` },
            },
        },
    };

    return { data, options };
};

/** CSV Importer */
export const validateCsvUploadColumn = (value) => {
    const parsedValue = Number(value);

    if ((!parsedValue && parsedValue !== 0) || parsedValue < 0)
        return {
            success: false,
            message: i18next.t('Minimum valid value is 0'),
        };

    return { success: true };
};

export const createGenerationProfile = (csvData, config) => {
    if (!csvData || !config) return;

    const { columnsFormat } = config;

    let format = columnsFormat?.date || 'dd/MM/yyyy';
    const hasTimeColumn = !format.includes('HH') && columnsFormat?.time;

    if (hasTimeColumn) format = `${format} ${columnsFormat.time}`;

    let firstDate = csvData[0].date;

    if (hasTimeColumn) firstDate = `${firstDate} ${csvData[0].time}`;

    const parsedDate = parseDate(firstDate, format);

    const year = parsedDate.getFullYear() || 2021;

    const { resultData, totals } = normalizeCsvDataToHourlyArray({
        columnsFormat,
        columnsToFill: [
            { name: 'generation', returnTotal: true, resolution: 'sum' },
        ],
        generateDate: false,
        initialDateUTC: Date.UTC(year, 0, 1, 0, 0, 0, 0),
        rowsData: csvData,
    });

    const totalGeneration = totals?.generation / 100 || 0;

    const profile = resultData.map((row) =>
        (row.generation / totalGeneration || 0).toFixed(15),
    );
    const profileFormatted = getDataProfileFormatted(profile, year);

    return { name: config?.file?.name || '', profile, profileFormatted, year };
};
