import { getEnergyConceptsConfigAsArray } from './helpers';

export const NAME = 'after-sales-consumption-history';

export const CAPTURE_MODE = {
    AUTOMATIC: 2,
    CALCULATED: 1,
    USER: 0,
};

export const DEMAND_METHODS = {
    CURRENT: 2,
    ESTIMATED: 1,
    MANUAL: 3,
    MEASUREMENT_SOURCE: 0,
};

export const DEMAND_CONCEPTS = {
    MEASURED: 'demand',
};

export const ENERGY_CONCEPTS = {
    BAG_ENERGY: 'pool',
    CONSUMPTION: 'consumption',
    EXPORTED_ENERGY: 'exported_energy',
    GENERATION: 'generation',
    GRID_CONSUMPTION: 'grid_consumption',
    NET_CONSUMPTION: 'net_consumption',
    RAW_NET_CONSUMPTION: 'raw_net_consumption',
    SELF_CONSUMPTION: 'selfconsumption',
};

export const ENERGY_CONCEPTS_CONFIG = {
    [ENERGY_CONCEPTS.CONSUMPTION]: {
        label: 'Total consumption',
        rustyKey: 'consumption',
    },
    [ENERGY_CONCEPTS.GRID_CONSUMPTION]: {
        label: 'Consumption from Grid',
        rustyKey: 'gridConsumption',
    },
    [ENERGY_CONCEPTS.SELF_CONSUMPTION]: {
        label: 'Self-consumption',
        rustyKey: 'selfConsumption',
    },
    [ENERGY_CONCEPTS.GENERATION]: {
        label: 'Total generation',
        rustyKey: 'generation',
    },
    [ENERGY_CONCEPTS.EXPORTED_ENERGY]: {
        label: 'Exported solar generation',
        rustyKey: 'exportedEnergy',
    },
    [ENERGY_CONCEPTS.NET_CONSUMPTION]: {
        label: 'Net consumption',
        rustyKey: 'netConsumption',
    },
    [ENERGY_CONCEPTS.BAG_ENERGY]: { label: 'Pool', rustyKey: 'bagEnergy' },
    [ENERGY_CONCEPTS.RAW_NET_CONSUMPTION]: {
        label: 'Net consumption',
        rustyKey: 'rawNetConsumption',
    },
};

export const CONCEPT_BY_RUSTY_KEY = Object.entries(
    ENERGY_CONCEPTS_CONFIG,
).reduce((acc, [key, value]) => {
    acc[value.rustyKey] = key;
    return acc;
}, {});

export const GROUPED_ENERGY_FIELDS = [
    {
        defaultExpanded: true,
        label: 'Consumption',
        fields: getEnergyConceptsConfigAsArray([
            ENERGY_CONCEPTS.CONSUMPTION,
            ENERGY_CONCEPTS.GRID_CONSUMPTION,
            ENERGY_CONCEPTS.SELF_CONSUMPTION,
        ]),
    },
    {
        defaultExpanded: false,
        label: 'Generation',
        fields: getEnergyConceptsConfigAsArray([
            ENERGY_CONCEPTS.GENERATION,
            ENERGY_CONCEPTS.EXPORTED_ENERGY,
        ]),
    },
    {
        defaultExpanded: false,
        label: 'Net metering',
        onlyNetmet: true,
        compensationSchemes: ['NETMET', 'NETMETS'],
        fields: getEnergyConceptsConfigAsArray([
            ENERGY_CONCEPTS.NET_CONSUMPTION,
            ENERGY_CONCEPTS.BAG_ENERGY,
        ]),
    },
];

export const FORM_FIELDS_BY_SECTION = {
    UPLOAD: ['file'],
    GENERAL: [
        'contracted_demand',
        'demand',
        'demand_method',
        'final_date',
        'hourly_contracted_demand',
        'initial_date',
        'is_bimonthly',
        'no_solar_demand',
        'power_factor',
        'rate',
        'rate_division_summer',
        'subsidy_rate',
    ],
    MONITORING: [],
    BALANCE: [],
};

export const UPLOAD_ORIGINS = {
    CSV: 1,
    CUPS: 4,
    LISA: 0,
    XML: 2,
    ZAP: 3,
};
