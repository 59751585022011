import React, { useEffect } from 'react';

import AddIcon from '@mui/icons-material/Add';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Button } from 'sunwise-ui';

import PreviewLocation from 'common/components/PreviewLocation';
import { DEFAULT_MAP_PREVIEW_ZOOM } from 'common/constants';

import * as actions from './actions';
import FormLocation from './components/FormLocation';
import { getFormName } from './helpers';
import * as selectors from './selectors';

const Container = ({
    addressPlaceholder,
    canModify,
    center,
    editMode,
    geocode,
    geocodeResult,
    handleSubmit,
    heightPreviewMap,
    initPosition = {},
    initialValues,
    initializeForm,
    isForLayout,
    isOpenMapPreview = false,
    name,
    resetForm,
    setEditMode,
    showDetailedAddressFields,
}) => {
    const { t } = useTranslation();
    useEffect(() => {
        if (editMode) {
            const values = {
                city: get(initPosition, 'position.city', ''),
                cp: get(initPosition, 'position.zip_code', ''),
                description: initPosition.description,
                extra_position_data: {
                    advanced: initPosition.position?.advanced || false,
                    external_house_number:
                        initPosition.position?.external_house_number || '',
                    internal_house_number:
                        initPosition.position?.internal_house_number || '',
                    neighborhood: initPosition.position?.neighborhood || '',
                    street: initPosition.position?.street || '',
                },
                hasPostalAddress: initPosition.position
                    ? initPosition.position.additional_information
                        ? true
                        : false
                    : false,
                id: initPosition.id ? initPosition.id : 0,
                political_division1: get(
                    initPosition,
                    'position.political_division1.id',
                    null,
                ),
                position: {
                    latitude: get(initPosition, 'position.latitude', null),
                    longitude: get(initPosition, 'position.longitude', null),
                },
                postalAddress: get(
                    initPosition,
                    'position.additional_information',
                    '',
                ),
                title: initPosition.title,
            };
            initializeForm(values);
        } else {
            resetForm();
        }
        return () => {
            resetForm();
        };
    }, [editMode]);

    if (editMode)
        return (
            <FormLocation
                addressPlaceholder={addressPlaceholder}
                center={center}
                errors={[]}
                geocodeResult={geocodeResult}
                handleClickCancel={() => setEditMode(false)}
                handleGeocode={geocode}
                initialValues={initialValues}
                isDisabled={!canModify}
                isForLayout={isForLayout}
                name={name}
                onSubmit={handleSubmit}
                showDetailedAddressFields={showDetailedAddressFields}
                zoom={DEFAULT_MAP_PREVIEW_ZOOM}
            />
        );

    if (get(initPosition, 'position', null) === null)
        return (
            <Button
                color="secondary"
                endIcon={<AddIcon />}
                fullWidth
                onClick={() => setEditMode(true)}
                variant="outlined"
                visible={canModify}
            >
                {t('Add location')}
            </Button>
        );

    return (
        <PreviewLocation
            heightPreviewMap={heightPreviewMap}
            isOpen={isOpenMapPreview}
            position={initPosition}
        />
    );
};

const mapStateToProps = createStructuredSelector({
    geocodeResult: selectors.getFetchGeocodeData,
    initialValues: selectors.getInitialValues,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    initializeForm: (values) =>
        dispatch(actions.initializeForm(values, getFormName(ownProps.name))),
    geocode: (str) =>
        dispatch(actions.fetchGeocodeResult(str, getFormName(ownProps.name))),
    resetForm: () => dispatch(actions.resetForm(getFormName(ownProps.name))),
});

Container.propTypes = {
    addressPlaceholder: PropTypes.string,
    canModify: PropTypes.bool,
    center: PropTypes.object,
    editMode: PropTypes.bool,
    geocode: PropTypes.func,
    geocodeResult: PropTypes.array,
    handleSubmit: PropTypes.func,
    heightPreviewMap: PropTypes.string,
    initializeForm: PropTypes.func,
    initialValues: PropTypes.object,
    initPosition: PropTypes.object,
    isForLayout: PropTypes.bool,
    isOpenMapPreview: PropTypes.bool,
    name: PropTypes.string,
    resetForm: PropTypes.func,
    setEditMode: PropTypes.func,
    showDetailedAddressFields: PropTypes.bool,
};

export default connect(mapStateToProps, mapDispatchToProps)(Container);
