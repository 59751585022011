import React, { Suspense } from 'react';

import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { client as WiseClient } from '@sunwise-clients/wise';
import { ConnectedRouter } from 'connected-react-router';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import App from './App';
import './i18n';
import './index.css';
import * as serviceWorker from './serviceWorker';
import configureStore, { history } from './store';
import { initAllThirdParty } from './thirdParty';

Bugsnag.start({
    apiKey: import.meta.env.VITE_BUGSNAG_API_KEY,
    appVersion: `${import.meta.env.VITE_VERSION}`,
    enabledReleaseStages: ['test', 'staging', 'prod'],
    plugins: [new BugsnagPluginReact()],
    releaseStage: import.meta.env.VITE_ENV,
});

initAllThirdParty();

WiseClient.setConfig({
    auth: () => localStorage.getItem('token'),
    baseURL: import.meta.env.VITE_OCR_API_URL,
});

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

const store = configureStore();

ReactDOM.render(
    <ErrorBoundary>
        <Provider store={store}>
            <ConnectedRouter history={history}>
                <Suspense fallback={null}>
                    <App />
                </Suspense>
            </ConnectedRouter>
        </Provider>
    </ErrorBoundary>,
    document.getElementById('root'),
);

serviceWorker.unregister();
