import { createSelector } from 'reselect';

import { NAME } from './constants';

export const getModel = (state) => state[NAME];

export const getInitialValuesApiSource = createSelector(
    getModel,
    (model) => model.initialValuesApiSource,
);

export const getInitialValuesExternalSource = createSelector(
    getModel,
    (model) => model.initialValuesExternalSource,
);

export const getInitialValuesSettings = createSelector(
    getModel,
    (model) => model.initialValuesSettings,
);

export const getInitialValuesSourceFields = createSelector(
    getModel,
    (model) => model.initialValuesSourceFields,
);

export const getSaveDataModel = createSelector(
    getModel,
    (model) => model.saveData,
);

export const getIsSavingData = createSelector(
    getSaveDataModel,
    (model) => model?.isLoading,
);

/* power stations  */
export const getPowerStationsModel = createSelector(
    getModel,
    (model) => model.powerStations,
);

export const getPowerStationsData = createSelector(
    getPowerStationsModel,
    (model) => model.data || {},
);

export const getPowerStationsDataId = createSelector(
    getPowerStationsData,
    (data) => data?.id,
);

export const getPowerStationsDataStartDate = createSelector(
    getPowerStationsData,
    (data) => data?.start_date,
);

export const getPowerStationsList = createSelector(
    getPowerStationsData,
    (data) => data?.projects || [],
);

export const getPowerStationsProviders = createSelector(
    getPowerStationsList,
    (data) => {
        const providers = data?.map((item) => item.provider_id) || [];
        return Array.from(new Set(providers));
    },
);

export const getPowerStationsIds = createSelector(
    getPowerStationsList,
    (list) => list?.map((item) => item.id) || [],
);

export const getIsFetchingPowerStations = createSelector(
    getPowerStationsModel,
    (model) => model.isLoading,
);

export const getApiSourceFormModalOpenStatus = createSelector(
    getModel,
    (model) => model.apiSourceFormModalOpenStatus,
);

export const getHasPowerStations = createSelector(
    getPowerStationsList,
    (list) => list?.length > 0,
);

export const getSourceFieldsModalFormOpenStatus = createSelector(
    getModel,
    (model) => model.sourceFieldsModalFormOpenStatus,
);

/* External sources */
export const getExternalSourcesModel = createSelector(
    getModel,
    (model) => model.externalSources,
);

export const getExternalSourcesData = createSelector(
    getExternalSourcesModel,
    (model) => model?.data,
);

export const getExternalSourcesErrors = createSelector(
    getExternalSourcesModel,
    (model) => model?.errors,
);

export const getIsFetchingExternalSources = createSelector(
    getExternalSourcesModel,
    (model) => model?.isLoading,
);

export const getExternalSourceFormModalOpenStatus = createSelector(
    getModel,
    (model) => model.externalSourceFormModalOpenStatus,
);

/* Location  */
export const getDefaultLocationModel = createSelector(
    getModel,
    (model) => model.defaultLocation,
);

export const getDefaultLocationData = createSelector(
    getDefaultLocationModel,
    (model) => model.data,
);

export const getIsFetchingDefaultLocation = createSelector(
    getDefaultLocationModel,
    (model) => model.isLoading,
);

export const getSettingsFormModalOpenStatus = createSelector(
    getModel,
    (model) => model.settingsFormModalOpenStatus,
);
