import React, { useEffect } from 'react';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { get, isEmpty, isNull } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Box, Button, Card, Chip, Skeleton, Typography } from 'sunwise-ui';

import { CreditDocuments, TitleWithDetail } from 'common/components';
import StepperTimeline from 'common/components/StepperTimeline';
import { BRIGHT_UID, KAM_UID, SERFIMEX_UID, STATUS } from 'common/constants';
import chatView from 'common/modules/chatView';
import { CREDIT_CHAT_TYPE } from 'common/modules/chatView/constants';
import { cleanJSON } from 'common/utils/helpers';
import { getSessionLocalStorage } from 'common/utils/helpers/session';

import * as afterSalesMeasurementSourceActions from '../../../afterSalesMeasurementSource/actions';
import ApiSourceModal from '../../../afterSalesMeasurementSource/components/ApiSourceModal';
import SettingsModal from '../../../afterSalesMeasurementSource/components/SettingsModal';
import * as afterSalesMeasurementSourceSelectors from '../../../afterSalesMeasurementSource/selectors';
import * as afterSalesSettingsActions from '../../../afterSalesSettings/actions';
import AfterSalesSettingModal from '../../../afterSalesSettings/components/ModalForm';
import * as afterSalesSettingsSelectors from '../../../afterSalesSettings/selectors';
import * as credentialIntegrationsActions from '../../../credentialIntegrations/actions';
import CreditBureauCard from '../../../creditBureauCard';
import * as actions from '../../actions';
import { MORAL_PERSON_TYPE } from '../../constants';
import {
    getIsShowContracts,
    getIsShowDocuments,
    getIsShowInsuranceDocuments,
    getIsShowRequestDocuments,
} from '../../helpers';
import * as selectors from '../../selectors';

import AllianceDetailsRequest from './AllianceDetailsRequest';
import AllianceWarning from './AllianceWarning';
import CancelDropdown from './CancelDropdown';
import ConditionalApprovedWarning from './ConditionalApprovedWarning';
import InstallerCard from './InstallerCard';

const Container = ({
    afterSalesSettingsData,
    cancelCreditApp,
    contracts,
    creditItem,
    documents,
    fetchAfterSalesSettings,
    fetchCommercialOffers,
    fetchCreditBureauData,
    fetchCreditDocuments,
    fetchCustomerEnergiesConsumptions,
    fetchPowerStations,
    fetchProviders,
    handleToggleDrawer,
    insuranceDocuments,
    isArchived,
    isFetchingAfterSalesSettings,
    isFetchingCustomerConsumption,
    isFetchingPowerStations,
    isOpenDrawer,
    onClickStatus,
    powerStationsData,
    prepareAddApiSource,
    prepareApproveChangesOnCredit,
    prepareCreateAfterSalesSettings,
    projectId,
    requestFormDocuments,
    setSelectedCreditItem,
}) => {
    const { t } = useTranslation();

    useEffect(() => {
        const creditAppId = get(creditItem, 'creditAppId', null);
        if (!isNull(creditAppId)) {
            fetchCreditDocuments(creditAppId);
        }
        if (get(creditItem, 'hasRequestBureau', false) && offerId) {
            fetchCreditBureauData(offerId);
        }
    }, [creditItem]);

    useEffect(() => {
        if (projectId) {
            fetchAfterSalesSettings(projectId);
            fetchCustomerEnergiesConsumptions(projectId);
            fetchPowerStations(projectId);
            fetchProviders(projectId);
        }
    }, [projectId]);

    if (isEmpty(creditItem)) return null;

    const {
        credit_type: creditType,
        creditAppId,
        currency,
        financier_installer,
        financier_product,
        hasAftersalesConfiguration,
        hasRequestBureau,
        infonavit_data,
        json_values,
        offerId,
        typesBusiness,
    } = creditItem;

    const handleOnClickApproveChangesOnCredit = () =>
        prepareApproveChangesOnCredit(creditItem, () => {
            fetchCommercialOffers({ isArchived, projectId });
            handleToggleDrawer(false);
        });

    const name = get(financier_product, 'name', '');
    const description = get(financier_product, 'description', '');
    const allianceStatus = get(financier_installer, 'status', null);
    const creditStatus = get(creditItem, 'status', null);
    const infonavitData = infonavit_data
        ? JSON.parse(cleanJSON(infonavit_data || '{}'))
        : {};
    const jsonValues = json_values
        ? JSON.parse(cleanJSON(json_values || '{}'))
        : {};
    const agent = get(financier_installer, 'agent', {});
    const primaTotal = get(jsonValues, 'prima_total', 0);
    const subTotal = get(jsonValues, 'subtotal', 0);
    const financierId = get(financier_installer, 'financier.id', '');
    const financierName = get(financier_installer, 'financier.name', '');
    const isBright = financierId === BRIGHT_UID;
    const isKam = financierId === KAM_UID;
    const isSerfimex = financierId === SERFIMEX_UID;
    const isMoralPerson = typesBusiness === MORAL_PERSON_TYPE;
    const isCancelledCreditStatus =
        creditStatus === STATUS.CANCELLED_STATUS.key;
    const isClosed = creditStatus === STATUS.CLOSED_STATUS.key;
    const showChat = get(
        financier_installer,
        'financier.chat_documents',
        false,
    );
    const offerIdWithAftersales = get(
        afterSalesSettingsData,
        'commercial_offer.id',
        null,
    );
    const powerStations = get(powerStationsData, 'projects', []);
    const powerStationsDataId = get(powerStationsData, 'id', null);

    const getIsDisabledMonitoringButton = () => {
        if (offerIdWithAftersales) return offerId !== offerIdWithAftersales;
        return false;
    };

    const renderMonitoringButton = () => {
        if (
            isFetchingAfterSalesSettings ||
            isFetchingCustomerConsumption ||
            isFetchingPowerStations
        )
            return <Skeleton variant="text" sx={{ fontSize: '1rem' }} />;
        if (!hasAftersalesConfiguration || isEmpty(afterSalesSettingsData))
            return (
                <Button
                    disabled={getIsDisabledMonitoringButton()}
                    fullWidth
                    onClick={() => prepareCreateAfterSalesSettings()}
                    variant="outlined"
                >
                    {t('Link monitoring')}
                </Button>
            );
        if (
            hasAftersalesConfiguration &&
            (isEmpty(powerStationsData) || isEmpty(powerStations))
        )
            return (
                <Button
                    fullWidth
                    onClick={() =>
                        prepareAddApiSource(projectId, powerStationsDataId)
                    }
                    variant="outlined"
                >
                    {t('Add source')}
                </Button>
            );
        if (hasAftersalesConfiguration && !isEmpty(powerStations))
            return (
                <Chip
                    color="primary"
                    icon={<CheckCircleIcon fontSize="small" />}
                    label={t('Linked monitoring')}
                    sx={{
                        width: '100%',
                        '& .MuiChip-label': { fontWeight: 700 },
                    }}
                />
            );
        return null;
    };

    return (
        <Box display="flex" flexDirection="column">
            <Box display="flex" alignItems="center" mb={1}>
                <TitleWithDetail fontSize="16">{t('Request')}</TitleWithDetail>
                {cancelCreditApp && (
                    <CancelDropdown
                        cancelCreditApp={cancelCreditApp}
                        status={creditStatus}
                    />
                )}
            </Box>

            <Box sx={{ minHeight: '60px' }} mb={2}>
                <StepperTimeline status={creditStatus} />
            </Box>

            <ConditionalApprovedWarning
                financierName={financierName}
                handleOnClickApproveChangesOnCredit={
                    handleOnClickApproveChangesOnCredit
                }
                status={creditStatus}
            />

            <AllianceWarning
                allianceStatus={allianceStatus}
                creditType={creditType}
                session={getSessionLocalStorage()}
            />

            <InstallerCard agent={agent} />

            <AllianceDetailsRequest
                creditItem={creditItem}
                creditType={creditType}
                currency={currency}
                infonavitData={infonavitData}
                isKam={isKam}
                primaTotal={primaTotal}
                subTotal={subTotal}
            />

            <Card>
                <Card.Header>
                    <TitleWithDetail fontSize="16">{name}</TitleWithDetail>
                </Card.Header>
                <Card.Body>
                    <Typography variant="body2">{description}</Typography>
                </Card.Body>
            </Card>

            {isClosed && (
                <Card>
                    <Card.Header>
                        <TitleWithDetail fontSize="16">
                            {t('Monitoring')}
                        </TitleWithDetail>
                    </Card.Header>
                    <Card.Body>{renderMonitoringButton()}</Card.Body>
                </Card>
            )}

            {!isMoralPerson && !isBright && !isSerfimex && !isKam && (
                <CreditBureauCard.Container
                    creditItem={creditItem}
                    hasRequestBureau={hasRequestBureau}
                    isArchived={isArchived}
                    isOpenDrawer={isOpenDrawer}
                    offerId={offerId}
                    projectId={projectId}
                    setSelectedCreditItem={setSelectedCreditItem}
                />
            )}

            {getIsShowRequestDocuments(
                creditType,
                hasRequestBureau,
                isBright,
                isMoralPerson,
                isSerfimex,
                requestFormDocuments,
            ) && (
                <Card>
                    <Card.Header>
                        <TitleWithDetail fontSize="16">
                            {t('Request form')}
                        </TitleWithDetail>
                    </Card.Header>
                    <Card.Body>
                        <CreditDocuments
                            documents={requestFormDocuments}
                            onClickStatus={onClickStatus}
                            preDownload={true}
                            isDisabledStatus={isCancelledCreditStatus}
                        />
                    </Card.Body>
                </Card>
            )}

            {getIsShowInsuranceDocuments(
                creditType,
                hasRequestBureau,
                insuranceDocuments,
                isBright,
                isMoralPerson,
                isSerfimex,
            ) && (
                <Card>
                    <Card.Header>
                        <TitleWithDetail fontSize="16">
                            {t('Insurance application form')}
                        </TitleWithDetail>
                    </Card.Header>
                    <Card.Body>
                        <CreditDocuments
                            documents={insuranceDocuments}
                            onClickStatus={onClickStatus}
                            preDownload={true}
                            isDisabledStatus={isCancelledCreditStatus}
                        />
                    </Card.Body>
                </Card>
            )}

            {getIsShowDocuments(
                documents,
                hasRequestBureau,
                isBright,
                isMoralPerson,
                isSerfimex,
            ) && (
                <Card>
                    <Card.Header>
                        <TitleWithDetail fontSize="16">
                            {t('Document', { count: 2 })}
                        </TitleWithDetail>
                    </Card.Header>
                    <Card.Body>
                        <CreditDocuments
                            documents={documents}
                            onClickStatus={onClickStatus}
                            preDownload={false}
                            isDisabledStatus={isCancelledCreditStatus}
                        />
                    </Card.Body>
                </Card>
            )}

            {getIsShowContracts(
                contracts,
                hasRequestBureau,
                isBright,
                isMoralPerson,
                isSerfimex,
            ) && (
                <Card>
                    <Card.Header>
                        <TitleWithDetail fontSize="16">
                            {t('Contract')}
                        </TitleWithDetail>
                    </Card.Header>
                    <Card.Body>
                        <CreditDocuments
                            documents={contracts}
                            onClickStatus={onClickStatus}
                            preDownload={true}
                            isDisabledStatus={isCancelledCreditStatus}
                        />
                    </Card.Body>
                </Card>
            )}

            {showChat && (
                <Card>
                    <Card.Header>
                        <TitleWithDetail fontSize="16">
                            {t('Chat')}
                        </TitleWithDetail>
                    </Card.Header>
                    <Card.Body>
                        <chatView.Container
                            type={CREDIT_CHAT_TYPE}
                            entityId={creditAppId}
                            user={get(financier_installer, 'installer', {})}
                        />
                    </Card.Body>
                </Card>
            )}

            <AfterSalesSettingModal
                canModifyAdvancedFeatures={true}
                canViewAdvancedFeatures={true}
                fromFinancing
                selectedProjectId={projectId}
                selectedCommercialOfferId={offerId}
                onSuccess={() =>
                    setSelectedCreditItem({
                        ...creditItem,
                        hasAftersalesConfiguration: true,
                    })
                }
            />

            <SettingsModal sunwiseProjectId={projectId} />

            <ApiSourceModal fromFinancing sunwiseProjectId={projectId} />
        </Box>
    );
};

const mapStateToProps = createStructuredSelector({
    afterSalesSettingsData: afterSalesSettingsSelectors.getFetchDataData,
    contracts: selectors.getContractsList,
    documents: selectors.getDocumentsList,
    insuranceDocuments: selectors.getInsuranceDocumentsList,
    isFetchingAfterSalesSettings:
        afterSalesSettingsSelectors.getFetchDataIsLoading,
    isFetchingCustomerConsumption:
        afterSalesSettingsSelectors.getIsFetchingCustomerConsumption,
    isFetchingPowerStations:
        afterSalesMeasurementSourceSelectors.getIsFetchingPowerStations,
    powerStationsData:
        afterSalesMeasurementSourceSelectors.getPowerStationsData,
    requestFormDocuments: selectors.getRequestFormDocumentsList,
});

const mapDispatchToProps = (dispatch) => ({
    fetchAfterSalesSettings: (projectId) =>
        dispatch(afterSalesSettingsActions.fetchAfterSalesSettings(projectId)),
    fetchCreditBureauData: (comercialOfferUUID) =>
        dispatch(CreditBureauCard.actions.fetchBureauData(comercialOfferUUID)),
    fetchCreditDocuments: (creditAppID) =>
        dispatch(actions.fetchCreditDocuments(creditAppID)),
    fetchCustomerEnergiesConsumptions: (projectId) =>
        dispatch(
            afterSalesSettingsActions.fetchCustomerEnergiesConsumptions(
                projectId,
            ),
        ),
    fetchPowerStations: (projectId) =>
        dispatch(
            afterSalesMeasurementSourceActions.fetchPowerStations(projectId),
        ),
    fetchProviders: () =>
        dispatch(credentialIntegrationsActions.fetchProviders()),
    prepareAddApiSource: (projectId, powerStationsDataId) =>
        dispatch(
            afterSalesMeasurementSourceActions.prepareAddApiSource(
                projectId,
                powerStationsDataId,
            ),
        ),
    prepareApproveChangesOnCredit: (creditItem, onSuccess) =>
        dispatch(actions.prepareApproveChangesOnCredit(creditItem, onSuccess)),
    prepareCreateAfterSalesSettings: () =>
        dispatch(afterSalesSettingsActions.prepareCreate()),
});

Container.propTypes = {
    afterSalesSettingsData: PropTypes.object,
    cancelCreditApp: PropTypes.func,
    contracts: PropTypes.array,
    creditItem: PropTypes.object,
    documents: PropTypes.array,
    fetchAfterSalesSettings: PropTypes.func,
    fetchCommercialOffers: PropTypes.func,
    fetchCreditBureauData: PropTypes.func,
    fetchCreditDocuments: PropTypes.func,
    fetchCustomerEnergiesConsumptions: PropTypes.func,
    fetchPowerStations: PropTypes.func,
    fetchProviders: PropTypes.func,
    handleToggleDrawer: PropTypes.func,
    insuranceDocuments: PropTypes.array,
    isArchived: PropTypes.bool,
    isFetchingAfterSalesSettings: PropTypes.bool,
    isFetchingCustomerConsumption: PropTypes.bool,
    isFetchingPowerStations: PropTypes.bool,
    isOpenDrawer: PropTypes.bool,
    onClickStatus: PropTypes.func,
    powerStationsData: PropTypes.object,
    prepareAddApiSource: PropTypes.func,
    prepareApproveChangesOnCredit: PropTypes.func,
    prepareCreateAfterSalesSettings: PropTypes.func,
    projectId: PropTypes.string,
    requestFormDocuments: PropTypes.array,
    setSelectedCreditItem: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(Container);
