import React from 'react';

import AddIcon from '@mui/icons-material/Add';
import { styled, useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box, Button, Grid, Skeleton, Typography } from 'sunwise-ui';

import { PERMISSION_LIST } from 'common/constants/permissionsV2';
import withPermissions from 'common/hocs/withPermissions';
import { numberFormat } from 'common/utils/helpers';

import CostColumn from './CostColumn';
import DiscountColumn from './DiscountColumn';
import DropdownMenu from './DropdownMenu';
import { getSizeColumn } from './helpers';
import MarginColumn from './MarginColumn';
import ModelColumn from './ModelColumn';
import QuantityColumn from './QuantityColumn';
import SubtotalColumn from './SubtotalColumn';
import UnitPriceColumn from './UnitPriceColumn';

const StyledBoxContainer = styled(Box)(
    ({ theme }) => `
    background: ${theme.palette.mode === 'dark' ? '#282B30' : '#eef4fa'};
    border-radius: 8px;
    overflow: hidden;
`,
);

const INVERTER_ERROR_KEYS = {
    START_UP_VOLTAGE: 0,
    MPPT_CURRENT: 1,
    OVERSIZE_POWER: 2,
};

const ERROR_CONFIG = {
    [INVERTER_ERROR_KEYS.START_UP_VOLTAGE]: {
        color: '#ffdcdc',
        color_dark: '#521e25',
        label: 'Panel voltage is lower than required',
    },
    [INVERTER_ERROR_KEYS.MPPT_CURRENT]: {
        color: '#ffc107',
        color_dark: '#b38c17',
        label: 'Panel current is higher than allowed',
    },
    [INVERTER_ERROR_KEYS.OVERSIZE_POWER]: {
        color: '#ffdcdc',
        color_dark: '#521e25',
        label: 'Max power exceeded',
    },
};

const ReduxFieldArrayProposalItems = ({
    canModifyProduct,
    control,
    currencyIso,
    currencyLocale,
    currencySymbol,
    customerMode,
    formValues,
    getPermissionsByCode,
    handleChange = () => {},
    handleClickDelete,
    handleNormalizeDiscountField,
    handleNormalizeField,
    handleNormalizeFields,
    handleNormalizeMarginField,
    handleNormalizeModel,
    handleNormalizeModelField,
    handleNormalizePercentageDiscountField,
    handleRemove,
    hasCostBySize,
    initialValues,
    isInverter = false,
    isLoading,
    isLocked,
    isProductCosting = true,
    itemsData,
    maxItems,
    name,
    offerItems,
    prefixId,
    setValue,
    sizeUnit,
    typeChange,
}) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const { append, fields, remove } = useFieldArray({ control, name });

    const { canModify: canModifyCost } = getPermissionsByCode(
        PERMISSION_LIST.PROPOSAL_PRODUCTS_COST_PERMISSION,
    );
    const { canModify: canModifyDiscount } = getPermissionsByCode(
        PERMISSION_LIST.PROPOSAL_PRODUCTS_DISCOUNT_PERMISSION,
    );
    const { canModify: canModifyMargin } = getPermissionsByCode(
        PERMISSION_LIST.PROPOSAL_PRODUCTS_MARGIN_PERMISSION,
    );
    const { canModify: canModifyTotal } = getPermissionsByCode(
        PERMISSION_LIST.SEE_TOTAL_PRODUCTS,
    );

    let total = 0;

    const size = getSizeColumn(
        canModifyCost,
        canModifyDiscount,
        canModifyMargin,
    );
    const isDarkMode = theme.palette.mode === 'dark';

    const isDisabled = !canModifyProduct || isLocked;

    return (
        <>
            {fields.map((item, i) => {
                const productItem = formValues?.items?.[i] || {};
                total += productItem.final_cost;

                const hasError = isInverter && !productItem.is_compatible;
                const errorConfig = hasError
                    ? ERROR_CONFIG[productItem.incompatibility_reason]
                    : null;
                const isDisabledField = isDisabled || !productItem.item;

                return (
                    <StyledBoxContainer
                        id={`proposal_generator_${prefixId}_card_item_${i + 1}`}
                        key={item.id}
                        mb={2}
                        sx={{
                            backgroundColor:
                                isDarkMode && errorConfig?.color_dark
                                    ? errorConfig.color_dark
                                    : errorConfig?.color,
                        }}
                    >
                        <Grid container>
                            <Grid size={{ md: 14, xs: 18 }}>
                                <Grid container p={2}>
                                    <Box
                                        sx={{
                                            display: {
                                                xs: 'block',
                                                md: 'none',
                                            },
                                            ml: 'auto',
                                            mt: 1,
                                            p: isLocked ? 1 : 0,
                                        }}
                                    >
                                        <DropdownMenu
                                            canModifyProduct={canModifyProduct}
                                            handleClickDelete={() =>
                                                handleClickDelete(
                                                    remove,
                                                    handleRemove,
                                                    i,
                                                    productItem,
                                                )
                                            }
                                            isLocked={isLocked}
                                        />
                                    </Box>
                                    <QuantityColumn
                                        control={control}
                                        formValues={formValues}
                                        handleNormalizeField={
                                            handleNormalizeField
                                        }
                                        handleNormalizeFields={
                                            handleNormalizeFields
                                        }
                                        index={i}
                                        initialValues={initialValues}
                                        isDisabledField={isDisabledField}
                                        name={name}
                                    />
                                    <ModelColumn
                                        control={control}
                                        errorConfig={errorConfig}
                                        formValues={formValues}
                                        handleChange={handleChange}
                                        handleNormalizeModel={
                                            handleNormalizeModel
                                        }
                                        handleNormalizeModelField={
                                            handleNormalizeModelField
                                        }
                                        index={i}
                                        initialValues={initialValues}
                                        isDisabled={isDisabled}
                                        isDisabledField={isDisabledField}
                                        isInverter={isInverter}
                                        itemsData={itemsData}
                                        name={name}
                                        offerItems={offerItems}
                                        productItem={productItem}
                                        setValue={setValue}
                                    />
                                    {isProductCosting && (
                                        <>
                                            <CostColumn
                                                canModifyCost={canModifyCost}
                                                canModifyProduct={
                                                    canModifyProduct
                                                }
                                                control={control}
                                                currencyIso={currencyIso}
                                                currencySymbol={currencySymbol}
                                                formValues={formValues}
                                                handleNormalizeField={
                                                    handleNormalizeField
                                                }
                                                handleNormalizeFields={
                                                    handleNormalizeFields
                                                }
                                                hasCostBySize={hasCostBySize}
                                                index={i}
                                                initialValues={initialValues}
                                                isDisabledField={
                                                    isDisabledField
                                                }
                                                isHidden={customerMode}
                                                name={name}
                                                size={size}
                                                sizeUnit={sizeUnit}
                                                totalSize={
                                                    productItem?.total_size
                                                }
                                                typeChange={typeChange}
                                            />
                                            <MarginColumn
                                                canModifyMargin={
                                                    canModifyMargin
                                                }
                                                canModifyProduct={
                                                    canModifyProduct
                                                }
                                                control={control}
                                                formValues={formValues}
                                                handleNormalizeFields={
                                                    handleNormalizeFields
                                                }
                                                handleNormalizeMarginField={
                                                    handleNormalizeMarginField
                                                }
                                                index={i}
                                                initialValues={initialValues}
                                                isDisabledField={
                                                    isDisabledField
                                                }
                                                isHidden={customerMode}
                                                name={name}
                                                productItem={productItem}
                                                size={size}
                                            />
                                            <UnitPriceColumn
                                                canModify={canModifyProduct}
                                                control={control}
                                                costingUnit={
                                                    productItem.costing_unit
                                                }
                                                customerMode={customerMode}
                                                index={i}
                                                name={name}
                                                prefixId={prefixId}
                                                size={size}
                                            />
                                            <DiscountColumn
                                                canModifyDiscount={
                                                    canModifyDiscount
                                                }
                                                canModifyProduct={
                                                    canModifyProduct
                                                }
                                                control={control}
                                                currencySymbol={currencySymbol}
                                                formValues={formValues}
                                                handleNormalizeDiscountField={
                                                    handleNormalizeDiscountField
                                                }
                                                handleNormalizeFields={
                                                    handleNormalizeFields
                                                }
                                                handleNormalizePercentageDiscountField={
                                                    handleNormalizePercentageDiscountField
                                                }
                                                index={i}
                                                initialValues={initialValues}
                                                isDisabledField={
                                                    isDisabledField
                                                }
                                                isHidden={customerMode}
                                                name={name}
                                                productItem={productItem}
                                                setValue={setValue}
                                                size={size}
                                            />
                                        </>
                                    )}
                                </Grid>
                            </Grid>
                            <SubtotalColumn
                                canModifyProduct={canModifyProduct}
                                currencyIso={currencyIso}
                                currencyLocale={currencyLocale}
                                handleClickDelete={handleClickDelete}
                                handleRemove={handleRemove}
                                index={i}
                                isLocked={isLocked}
                                isProductCosting={isProductCosting}
                                productItem={productItem}
                                remove={remove}
                            />
                        </Grid>
                    </StyledBoxContainer>
                );
            })}
            {canModifyTotal && isProductCosting && (
                <Grid container>
                    <Grid size={18}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: { xs: 'start', lg: 'end' },
                                textAlign: { xs: 'left', md: 'right' },
                                px: { xs: 2, md: 3 },
                                pb: 2,
                            }}
                        >
                            <Typography variant="body2">
                                {t('Total')}
                            </Typography>
                            {isLoading ? (
                                <Skeleton
                                    variant="text"
                                    sx={{
                                        fontSize: '1rem',
                                        width: { xs: 200, lg: 143 },
                                    }}
                                />
                            ) : (
                                <Typography variant="body2" fontWeight="bold">
                                    {numberFormat(total, {
                                        currency: currencyIso,
                                        locale: currencyLocale,
                                        style: 'currency',
                                    })}
                                </Typography>
                            )}
                        </Box>
                    </Grid>
                </Grid>
            )}
            <Grid container>
                <Grid size={{ xs: 'grow' }} textAlign="right">
                    <Button
                        variant="outlined"
                        endIcon={<AddIcon />}
                        disabled={
                            isDisabled ||
                            (Boolean(maxItems) &&
                                Boolean(
                                    maxItems <= formValues?.items?.length || 0,
                                ))
                        }
                        id={`proposal_generator_${prefixId}_add_button`}
                        onClick={() =>
                            append({
                                cost: 0,
                                discount: 0,
                                discount_type: 1,
                                final_cost: 0,
                                id: null,
                                is_compatible: true,
                                is_new: true,
                                item: null,
                                locked: 0,
                                margin: 0,
                                max_panels: 0,
                                min_panels: 0,
                                oversize: 0,
                                percentage_discount: 0,
                                quantity: 0,
                                temp_cost: 0,
                                temp_discount: 0,
                                temp_margin: 0,
                                temp_quantity: 0,
                                unit_price: 0,
                            })
                        }
                        sx={{ width: { xs: '100%', md: 'auto' } }}
                    >
                        {t('Add')}
                    </Button>
                </Grid>
            </Grid>
        </>
    );
};

ReduxFieldArrayProposalItems.propTypes = {
    canModifyProduct: PropTypes.bool,
    control: PropTypes.object,
    currencyIso: PropTypes.string,
    currencyLocale: PropTypes.string,
    currencySymbol: PropTypes.string,
    customerMode: PropTypes.bool,
    formValues: PropTypes.object,
    getPermissionsByCode: PropTypes.func,
    handleChange: PropTypes.func,
    handleClickDelete: PropTypes.func,
    handleNormalizeDiscountField: PropTypes.func,
    handleNormalizeField: PropTypes.func,
    handleNormalizeFields: PropTypes.func,
    handleNormalizeMarginField: PropTypes.func,
    handleNormalizeModel: PropTypes.func,
    handleNormalizeModelField: PropTypes.func,
    handleNormalizePercentageDiscountField: PropTypes.func,
    handleRemove: PropTypes.func,
    hasCostBySize: PropTypes.bool,
    initialValues: PropTypes.object,
    isInverter: PropTypes.bool,
    isLoading: PropTypes.bool,
    isLocked: PropTypes.bool,
    isProductCosting: PropTypes.bool,
    itemsData: PropTypes.array,
    maxItems: PropTypes.number,
    name: PropTypes.string,
    offerItems: PropTypes.array,
    prefixId: PropTypes.string,
    setValue: PropTypes.func,
    sizeUnit: PropTypes.string,
    typeChange: PropTypes.number,
};

export default withPermissions([
    PERMISSION_LIST.PROPOSAL_PRODUCTS_COST_PERMISSION,
    PERMISSION_LIST.PROPOSAL_PRODUCTS_DISCOUNT_PERMISSION,
    PERMISSION_LIST.PROPOSAL_PRODUCTS_MARGIN_PERMISSION,
    PERMISSION_LIST.SEE_TOTAL_PRODUCTS,
])(ReduxFieldArrayProposalItems);
