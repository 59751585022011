import React from 'react';

import AddIcon from '@mui/icons-material/Add';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button } from 'sunwise-ui';

import Form from './Form';

const FormContainer = ({ isFormVisible, projectId, setIsFormVisible }) => {
    const { t } = useTranslation();

    const toggleFormVisibility = () => setIsFormVisible((prev) => !prev);
    const handleClickCancel = () => setIsFormVisible(false);

    if (!isFormVisible)
        return (
            <Button
                color="secondary"
                endIcon={<AddIcon />}
                fullWidth
                onClick={toggleFormVisibility}
                variant="outlined"
            >
                {t('Add note')}
            </Button>
        );

    return <Form handleClickCancel={handleClickCancel} projectId={projectId} />;
};

FormContainer.propTypes = {
    setIsFormVisible: PropTypes.func,
    projectId: PropTypes.string,
    isFormVisible: PropTypes.bool,
};

export default FormContainer;
