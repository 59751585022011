import i18next from 'i18next';

import { remove } from 'common/api/v2/projectsComments';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import {
    DELETE_NOTE,
    DELETE_NOTE_FAILURE,
    DELETE_NOTE_SUCCESS,
} from '../actionTypes';
import { projectActions } from '../reducer';

import fetchNotes from './fetchNotes';

export default (data) => (dispatch) => {
    const { noteId, projectId } = data;
    if (!noteId || !projectId) return;

    dispatch(projectActions[DELETE_NOTE]());

    remove(noteId)
        .then(() => {
            dispatch(projectActions[DELETE_NOTE_SUCCESS]());
            showToast({ body: i18next.t('Successfully deleted') });
            dispatch(fetchNotes(projectId));
        })
        .catch((error) => {
            dispatch(projectActions[DELETE_NOTE_FAILURE]());
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
