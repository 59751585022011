import React from 'react';

import PropTypes from 'prop-types';

import ItemList from './ItemList';

const List = ({ items }) =>
    items.map((item) => <ItemList key={item.id} item={item} />);

List.propTypes = { items: PropTypes.array };

export default List;
