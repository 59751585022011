import React from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Box, Divider, Typography } from 'sunwise-ui';

import DropdownCardMenu from 'common/components/cards/DropdownCardMenu';

import * as actions from '../../actions';

import Form from './Form';

const ItemList = ({ item, prepareDeleteNote }) => {
    const { t } = useTranslation();
    const [editionMode, setEditionMode] = React.useState(false);

    const { comment, created_at, created_by, id, project } = item;
    const { first_name, last_name } = created_by;

    const handleClickCancel = () => setEditionMode(false);
    const handleClickDelete = (noteId) =>
        prepareDeleteNote({ noteId, projectId: project });
    const handleClickEdit = () => setEditionMode(true);

    return (
        <Box sx={{ mt: 2 }}>
            <Box sx={{ alignItems: 'center', display: 'flex', gap: 2, mb: 1 }}>
                <Typography
                    fontWeight="bold"
                    variant="body2"
                >{`${first_name} ${last_name}`}</Typography>

                <Typography sx={{ ml: 'auto' }} variant="caption">
                    {created_at}
                </Typography>

                <Box>
                    <DropdownCardMenu
                        items={[
                            {
                                handleClick: () => handleClickEdit(id),
                                icon: <EditIcon fontSize="small" />,
                                label: t('Edit'),
                            },
                            {
                                handleClick: () => handleClickDelete(id),
                                icon: <DeleteIcon fontSize="small" />,
                                label: t('Delete'),
                            },
                        ]}
                        size="small"
                    />
                </Box>
            </Box>

            <Box sx={{ fontSize: '0.9rem' }}>
                {!editionMode ? (
                    comment
                ) : (
                    <Form
                        comment={comment}
                        editionMode={true}
                        handleClickCancel={handleClickCancel}
                        noteId={id}
                        projectId={project}
                    />
                )}
            </Box>

            <Divider sx={{ my: 2 }} />
        </Box>
    );
};

ItemList.propTypes = {
    item: PropTypes.object,
    prepareDeleteNote: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => ({
    prepareDeleteNote: (data) => dispatch(actions.prepareDeleteNote(data)),
});

export default connect(null, mapDispatchToProps)(ItemList);
