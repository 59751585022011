import i18next from 'i18next';
import isEmpty from 'lodash/isEmpty';

import alerts from 'common/modules/alerts';

import dropTracking from './dropTracking';

export default (element, filterData) => (dispatch) => {
    if (isEmpty(element)) return;

    const { id } = element;
    const dataAlert = {
        confirmText: i18next.t('Delete'),
        messages: [
            i18next.t(
                'Are you really sure to remove the "{{description}}" trace?',
                { description: element.description },
            ),
        ],
        onSuccess: () => dispatch(dropTracking(id, filterData)),
        title: i18next.t('Confirmation'),
        type: alerts.ALERT_TYPE_CONFIRM,
        variant: 'warning',
    };

    dispatch(alerts.actions.show(dataAlert));
};
