import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Mention, MentionsInput } from 'react-mentions';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Button, FormControl, Grid } from 'sunwise-ui';

import ShowErrors from 'common/components/ShowErrors';

import * as actions from '../../actions';
import * as selectors from '../../selectors';

import baseMentionsInputStyle from './mentionsInputStyle';
import mentionStyle from './mentionStyle';

const Form = ({
    comment = '',
    errors,
    handleClickCancel,
    initialValues,
    isSaving,
    noteId,
    projectId,
    save,
}) => {
    const { t } = useTranslation();

    const { handleSubmit, reset, setValue, watch } = useForm({
        defaultValues: { id: noteId, comment, project: projectId },
    });

    useEffect(() => reset(initialValues), [initialValues]);

    useEffect(
        () =>
            reset({
                ...initialValues,
                comment,
                id: noteId,
                project: projectId,
            }),
        [projectId],
    );

    const mentionsInputOnChange = (e) => setValue('comment', e.target.value);
    const mentionsInputStyle = {
        ...baseMentionsInputStyle,
        control: {
            backgroundColor: '#fff',
            borderRadius: '8px',
            padding: '8.5px 14px',
            height: '92px',
        },
    };
    const mentionsInputValue = watch('comment');

    const onSubmit = (values) => {
        save(values, () => {
            handleClickCancel();
        });
        reset();
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <FormControl fullWidth>
                <MentionsInput
                    onChange={mentionsInputOnChange}
                    placeholder={t('Add note')}
                    style={mentionsInputStyle}
                    value={mentionsInputValue}
                >
                    <Mention data={[]} style={mentionStyle} trigger="@" />
                </MentionsInput>
            </FormControl>

            <Grid container>
                <Grid size={18}>
                    <ShowErrors errors={errors} />
                </Grid>
            </Grid>

            <Grid container>
                <Grid
                    display="flex"
                    flexDirection={{ xs: 'column', md: 'row' }}
                    justifyContent={{ md: 'right', xs: 'center' }}
                    size={{ xs: 'grow' }}
                >
                    <Button
                        color="secondary"
                        onClick={handleClickCancel}
                        sx={{
                            mr: { md: 2, xs: 0 },
                            order: { md: 1, xs: 2 },
                            width: { md: 'auto', xs: '100%' },
                        }}
                        variant="text"
                    >
                        {t('Cancel')}
                    </Button>

                    <Button
                        color="secondary"
                        disabled={isSaving || !mentionsInputValue}
                        sx={{
                            mb: { md: 0, xs: 2 },
                            order: { md: 2, xs: 1 },
                            width: { md: 'auto', xs: '100%' },
                        }}
                        type="submit"
                        variant="outlined"
                    >
                        {t('Save')}
                    </Button>
                </Grid>
            </Grid>
        </form>
    );
};

const mapStateToProps = createStructuredSelector({
    isSaving: selectors.getSaveNoteIsSaving,
});

const mapDispatchToProps = (dispatch) => ({
    save: (values, callback) => dispatch(actions.saveNote(values, callback)),
});

Form.propTypes = {
    comment: PropTypes.string,
    errors: PropTypes.array,
    handleClickCancel: PropTypes.func,
    initialValues: PropTypes.object,
    isSaving: PropTypes.bool,
    noteId: PropTypes.string,
    projectId: PropTypes.string,
    save: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(Form);
