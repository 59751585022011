import { getTrackings } from 'common/api/v1/contactTrackings';

import {
    FETCH_TRACKINGS_NEXT,
    FETCH_TRACKINGS_NEXT_FAILURE,
    FETCH_TRACKINGS_NEXT_SUCCESS,
} from '../actionTypes';
import { trackingsActions } from '../reducer';
import * as selectors from '../selectors';

export default (filterData) => (dispatch, getState) => {
    const { projectId, status, type } = filterData;
    const state = getState();
    const contactId = selectors.getContactId(state);

    dispatch(trackingsActions[FETCH_TRACKINGS_NEXT]());

    const data = { contact: contactId };

    if (type !== 'all') data.type = type;
    if (status !== 'all') data.order = status;
    if (projectId) data.project = projectId;

    getTrackings(data)
        .then((response) => {
            let data = [...response.data.data];

            if (projectId) {
                data = data.filter((tracking) => {
                    return tracking?.project?.id === projectId;
                });
            }

            dispatch(trackingsActions[FETCH_TRACKINGS_NEXT_SUCCESS](data));
        })
        .catch((error) =>
            dispatch(trackingsActions[FETCH_TRACKINGS_NEXT_FAILURE](error)),
        );
};
