import API from '../..';
const ENTITY = 'contacts-trackings';

export const createTracking = (data) => API.post(`/api/v1/${ENTITY}/`, data);
export const deleteTracking = (id) => API.delete(`/api/v1/${ENTITY}/${id}`);
export const getTrackings = (data) =>
    API.get(`/api/v1/${ENTITY}/filter-type/`, {
        params: {
            contact: data?.contact,
            order: data?.order,
            project: data?.project,
            type: data?.type,
        },
    });
export const getTrackingsHours = () => API.get(`/api/v1/trackings-hours/`);
export const updateTracking = (data, id) =>
    API.put(`/api/v1/${ENTITY}/${id}`, data);
