import React from 'react';

import { useTheme } from '@mui/material/styles';
import { get, isNull } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Card, Link, Typography } from 'sunwise-ui';

import EmptyState from 'common/components/EmptyState';
import PlaceholderCardOffer from 'common/components/placeholder/PlaceholderCardOffer';
import StatusIndicatorTooltip from 'common/components/StatusIndicatorTooltip';
import { COMMERCIAL_OFFER_STATUS } from 'common/constants/commercialOffer';
import { getCurrencyIso, numberFormat } from 'common/utils/helpers';
import { getCountryCurrencyLocale } from 'common/utils/helpers/session';

import EmptyProposals from 'resources/EmptyProposals.png';
import EmptyStateArchived from 'resources/EmptyStateArchived.png';

import { getRequestCount } from '../helpers';

import BranchOfficeTooltip from './BranchOfficeTooltip';
import DateTooltip from './DateTooltip';
import FinancingSection from './FinancingSection';
import InteractiveLinkTooltip from './InteractiveLinkTooltip';
import MenuOptions from './MenuOptions';
import ReadOnlyTooltip from './ReadOnlyTooltip';
import SmartDocumentsTooltip from './SmartDocumentsTooltip';
import ViewsTooltip from './ViewsTooltip';

const ProposalTable = ({
    canDelete,
    canModify,
    commercialOffers,
    handleToggleDrawer,
    isArchivedFilter,
    isLoading,
    prepareCommercialOfferCreate,
    setSelectedCreditItem,
    setRef,
}) => {
    const { t } = useTranslation();
    const theme = useTheme();

    if (!isLoading && commercialOffers.length <= 0) {
        if (isArchivedFilter) {
            return (
                <EmptyState
                    srcImage={EmptyStateArchived}
                    sxCard={{ border: 0, boxShadow: 'none' }}
                    title={t('This project has no archived proposals')}
                />
            );
        } else {
            return (
                <EmptyState
                    canModify={canModify}
                    caption={t(
                        'Here you will see all the proposals of the project. Start creating one!',
                    )}
                    classNameCard="border-0"
                    classNameCardBody="__intercom_add_commercial_offer_button"
                    dataIntercomTarget="Add Commercial Offer (Button)"
                    onClick={prepareCommercialOfferCreate}
                    srcImage={EmptyProposals}
                    sxCard={{ border: 0, boxShadow: 'none' }}
                    textButton={t('Create')}
                    title={t('This project has no proposals')}
                />
            );
        }
    }

    const sxGrid = {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'noWrap',
        gap: 1.5,
        px: 1.5,
        py: 2,
    };

    const sxBox = {
        alignItems: 'center',
        display: 'flex',
        flexWrap: 'wrap',
        gap: 2,
        mt: 1,
    };

    return (
        <PlaceholderCardOffer ready={isLoading}>
            <Box>
                {commercialOffers.map((item) => {
                    const commercialOffer = get(
                        item,
                        'commercial_offer[0]',
                        {},
                    );
                    const {
                        currency,
                        has_approbed,
                        has_permalink,
                        id,
                        is_archived,
                        permalink,
                        proposal_summary_linked,
                        read_only,
                        status,
                        total,
                        types_business_company,
                    } = commercialOffer;
                    const {
                        created_at,
                        favorite,
                        id: itemId,
                        name,
                        project,
                    } = item;
                    const currencyIso = getCurrencyIso(currency);
                    const currencyLocale = getCountryCurrencyLocale();
                    const isGenerated =
                        status === COMMERCIAL_OFFER_STATUS.FINISHED.key;
                    const to = isGenerated
                        ? `/proposal-review-pro/${id}`
                        : `/proposal/${id}`;
                    const isReadOnly = read_only;
                    const style = {
                        border: isReadOnly
                            ? `1px solid ${theme.palette.info.light}`
                            : '',
                    };

                    return (
                        <Card key={`proposal-item-${id}`} style={style}>
                            <Box sx={sxGrid}>
                                <Box sx={{ px: 1 }}>
                                    <StatusIndicatorTooltip
                                        isApproved={has_approbed}
                                        isGenerated={isGenerated}
                                    />
                                </Box>

                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        width: '100%',
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                        }}
                                    >
                                        <Box>
                                            <Link
                                                color="inherit"
                                                component={RouterLink}
                                                to={to}
                                                underline="none"
                                            >
                                                <strong>{name}</strong>
                                            </Link>

                                            <Typography variant="body2">
                                                {numberFormat(total, {
                                                    currency: currencyIso,
                                                    locale: currencyLocale,
                                                    style: 'currency',
                                                })}
                                            </Typography>
                                        </Box>

                                        <MenuOptions
                                            canDelete={canDelete}
                                            canModify={canModify}
                                            creditStatus={get(
                                                commercialOffer,
                                                'credit_application.status',
                                                null,
                                            )}
                                            hasFinancingRequest={get(
                                                commercialOffer,
                                                'has_financing_request',
                                                false,
                                            )}
                                            hasPermalink={has_permalink}
                                            hasSummaryLinked={
                                                proposal_summary_linked
                                            }
                                            isApproved={has_approbed}
                                            isArchived={is_archived}
                                            isReadOnly={isReadOnly}
                                            isArchivedFilter={isArchivedFilter}
                                            isFavorite={favorite}
                                            isGenerated={isGenerated}
                                            offerId={id}
                                            offerName={name}
                                            permalink={permalink}
                                            projectId={project}
                                            proposalId={itemId}
                                        />
                                    </Box>

                                    <Box sx={sxBox}>
                                        <DateTooltip createdAt={created_at} />

                                        <BranchOfficeTooltip
                                            branchOffice={item?.branch_office}
                                        />

                                        <SmartDocumentsTooltip
                                            isHidden={!isGenerated}
                                            smartDocuments={get(
                                                commercialOffer,
                                                'smart_documents',
                                                [],
                                            )}
                                        />

                                        <InteractiveLinkTooltip
                                            isActive={has_permalink}
                                            isHidden={
                                                !isGenerated ||
                                                isNull(permalink)
                                            }
                                        />

                                        <ViewsTooltip
                                            isHidden={!isGenerated}
                                            offerId={id}
                                            viewsCounter={get(
                                                commercialOffer,
                                                'views_counter',
                                                0,
                                            )}
                                        />

                                        <ReadOnlyTooltip
                                            isReadOnly={isReadOnly}
                                        />
                                    </Box>
                                </Box>
                            </Box>

                            <FinancingSection
                                creditAppId={get(
                                    commercialOffer,
                                    'credit_application_id',
                                    null,
                                )}
                                creditItem={get(
                                    commercialOffer,
                                    'credit_item',
                                    {},
                                )}
                                creditStatus={get(
                                    commercialOffer,
                                    'credit_application.status',
                                    null,
                                )}
                                currency={get(commercialOffer, 'currency', {})}
                                hasAftersalesConfiguration={get(
                                    commercialOffer,
                                    'has_aftersales_configuration',
                                    false,
                                )}
                                hasClosedCredit={get(
                                    commercialOffer,
                                    'has_credit_close',
                                    false,
                                )}
                                hasFinancingRequest={get(
                                    commercialOffer,
                                    'has_financing_request',
                                    false,
                                )}
                                hasRequestBureau={get(
                                    commercialOffer,
                                    'has_request_buro',
                                    false,
                                )}
                                handleToggleDrawer={handleToggleDrawer}
                                isArchived={is_archived}
                                isGenerated={isGenerated}
                                offerId={id}
                                projectId={project}
                                proposalId={itemId}
                                requestCount={getRequestCount(commercialOffers)}
                                setSelectedCreditItem={setSelectedCreditItem}
                                setRef={setRef}
                                typesBusiness={types_business_company}
                            />
                        </Card>
                    );
                })}
            </Box>
        </PlaceholderCardOffer>
    );
};

ProposalTable.propTypes = {
    canDelete: PropTypes.bool,
    canModify: PropTypes.bool,
    commercialOffers: PropTypes.array,
    handleToggleDrawer: PropTypes.func,
    isArchivedFilter: PropTypes.bool,
    isLoading: PropTypes.bool,
    prepareCommercialOfferCreate: PropTypes.func,
    setSelectedCreditItem: PropTypes.func,
    setRef: PropTypes.func,
};

export default ProposalTable;
